import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEsgOrganization } from 'src/common/hooks';
import CustomSelect from 'src/components/CustomSelect';
import { NestedRows, TableRow } from './index.types';
import {
  StyledCell,
  StyledHeader,
  StyledRow,
  StyledRowSection,
} from './index.styles';
import { useTopicsTableData } from '../../hooks';
import {
  getBooleanOptions,
  getNumericOptions,
  getProbabilityNumericOptions,
} from '../../utils';

import { useSidebar } from 'src/views/components/Sidebar/hooks';
import EditModal from '../../Stakeholders/SelectingStakeholders/SelectingStakeholdersTable/EditModal';

interface Props {
  reportId: string;
  preliminary: boolean;
}

const MIN_CELL_WIDTH = 7.8;
const ROW_KEY_DELIMETER = ':::';

export default function NonFinancialTopicsEvaluation({
  reportId,
  preliminary,
}: Props) {
  const { t } = useTranslation();
  const { id: organizationId } = useEsgOrganization();
  const sidebarState = useSidebar();
  const { data, updateTableRow } = useTopicsTableData(
    organizationId,
    reportId,
    'nonfinancial',
    preliminary
  );

  const rows: NestedRows = (data || []).reduce(
    (rows: NestedRows, item: TableRow) => {
      const uniqueKey = `${item.esg_topic_key}${ROW_KEY_DELIMETER}${item.esg_topic_title}`;

      if (!rows[uniqueKey]) rows[uniqueKey] = [];
      rows[uniqueKey].push(item);
      return rows;
    },
    []
  );

  // the table is too wide; minimize sidebar on mount
  React.useEffect(() => {
    const { isOpen } = sidebarState;
    sidebarState.update({ isOpen: false });
    // restore previous state
    return () => {
      sidebarState.update({ isOpen });
    };
  }, []);

  const NUMERIC_OPTIONS = getNumericOptions(t);
  const BOOLEAN_OPTIONS = getBooleanOptions(t);
  const PROBABILITY_NUMERIC_OPTIONS = getProbabilityNumericOptions(t);

  return (
    <div style={{ width: '100%' }}>
      <StyledRow
        sx={{
          height: '40px',
          marginBottom: '-2px',
          borderBottom: '0px solid #eee',
        }}
      >
        <StyledHeader />
        <StyledHeader
          sx={{ width: `${MIN_CELL_WIDTH * 2}%`, backgroundColor: '#EEE' }}
        >
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.realPositive')}
        </StyledHeader>
        <StyledHeader
          sx={{ width: `${MIN_CELL_WIDTH * 3}%`, backgroundColor: '#F8F8F8' }}
        >
          {t(
            'esg.dma.nonFinancialTopicsEvaluationTable.columns.potentialPositive'
          )}
        </StyledHeader>
        <StyledHeader
          sx={{ width: `${MIN_CELL_WIDTH * 3}%`, backgroundColor: '#EEE' }}
        >
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.realNegative')}
        </StyledHeader>
        <StyledHeader
          sx={{ width: `${MIN_CELL_WIDTH * 4}%`, backgroundColor: '#F8F8F8' }}
        >
          {t(
            'esg.dma.nonFinancialTopicsEvaluationTable.columns.potentialNegative'
          )}
        </StyledHeader>
        {!preliminary && <StyledHeader sx={{ width: `${MIN_CELL_WIDTH}%` }} />}
      </StyledRow>
      <StyledRow sx={{ borderWidth: '2px' }}>
        <StyledCell>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.perspecitve')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#EEE' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.scale')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#EEE' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.range')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.scale')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.range')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.probability')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#EEE' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.scale')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#EEE' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.range')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#EEE' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.irreversible')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.scale')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.range')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.probability')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.irreversible')}
        </StyledCell>
        {!preliminary && (
          <StyledCell sx={{ fontWeight: 'bold' }}>
            {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.reasoning')}
          </StyledCell>
        )}
      </StyledRow>

      <div
        style={{
          height: '650px',
          overflowY: 'scroll',
          paddingRight: '10px',
          marginRight: '-10px',
        }}
      >
        {Object.keys(rows).map((uniqueKey: string) => {
          const [key, title] = uniqueKey.split(ROW_KEY_DELIMETER);
          const subrows = rows[uniqueKey];
          return (
            <>
              <StyledRow key={key} sx={{ height: '45px;' }}>
                <StyledRowSection>{title}</StyledRowSection>
              </StyledRow>

              {subrows.map((row: TableRow) => {
                const {
                  time_horizon: timeHorizon,
                  concrete_positive_scale: concretePositiveScale,
                  concrete_positive_range: concretePositiveRange,
                  potential_positive_scale: potentialPositiveScale,
                  potential_positive_range: potentialPositiveRange,
                  potential_positive_probability: potentialPositiveProbability,
                  concrete_negative_scale: concreteNegativeScale,
                  concrete_negative_range: concreteNegativeRange,
                  concrete_negative_irreversible: concreteNegativeIrreversible,
                  potential_negative_scale: potentialNegativeScale,
                  potential_negative_range: potentialNegativeRange,
                  potential_negative_probability: potentialNegativeProbability,
                  potential_negative_irreversible:
                    potentialNegativeIrreversible,
                  reasoning,
                } = row;
                return (
                  <StyledRow key={`${key}-${timeHorizon}`}>
                    <StyledCell>
                      {t(
                        `esg.dma.nonFinancialTopicsEvaluationTable.columns.${timeHorizon}`
                      )}
                    </StyledCell>
                    <StyledCell sx={{ backgroundColor: '#EEE' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-concretePositiveScale`}
                        value={concretePositiveScale}
                        options={NUMERIC_OPTIONS}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            concrete_positive_scale: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell sx={{ backgroundColor: '#EEE' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-concretePositiveRange`}
                        value={concretePositiveRange}
                        options={NUMERIC_OPTIONS}
                        disabled={concretePositiveScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            concrete_positive_range: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-potentialPositiveScale`}
                        value={potentialPositiveScale}
                        options={NUMERIC_OPTIONS}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            potential_positive_scale: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-potentialPositiveRange`}
                        value={potentialPositiveRange}
                        options={NUMERIC_OPTIONS}
                        disabled={potentialPositiveScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            potential_positive_range: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-potentialPositiveProbability`}
                        value={potentialPositiveProbability}
                        options={PROBABILITY_NUMERIC_OPTIONS}
                        disabled={potentialPositiveScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            potential_positive_probability: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell sx={{ backgroundColor: '#EEE' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-concreteNegativeScale`}
                        value={concreteNegativeScale}
                        options={NUMERIC_OPTIONS}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            concrete_negative_scale: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell sx={{ backgroundColor: '#EEE' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-concreteNegativeRange`}
                        value={concreteNegativeRange}
                        options={NUMERIC_OPTIONS}
                        disabled={concreteNegativeScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            concrete_negative_range: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell sx={{ backgroundColor: '#EEE' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-concreteNegativeIrreversible`}
                        value={concreteNegativeIrreversible}
                        options={BOOLEAN_OPTIONS}
                        disabled={concreteNegativeScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            concrete_negative_irreversible: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-potentialNegativeScale`}
                        value={potentialNegativeScale}
                        options={NUMERIC_OPTIONS}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            potential_negative_scale: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-potentialNegativeRange`}
                        value={potentialNegativeRange}
                        options={NUMERIC_OPTIONS}
                        disabled={potentialNegativeScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            potential_negative_range: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-potentialNegativeProbability`}
                        value={potentialNegativeProbability}
                        options={PROBABILITY_NUMERIC_OPTIONS}
                        disabled={potentialNegativeScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            potential_negative_probability: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-potentialNegativeIrreversible`}
                        value={potentialNegativeIrreversible}
                        options={BOOLEAN_OPTIONS}
                        disabled={potentialNegativeScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            potential_negative_irreversible: value,
                          })
                        }
                      />
                    </StyledCell>
                    {!preliminary && (
                      <StyledCell>
                        <EditModal
                          title={t(
                            'esg.dma.selectingStakeholders.table.modals.reasoning.title'
                          )}
                          value={reasoning}
                          disabled
                          onChange={(value) => {
                            updateTableRow({ ...row, reasoning: value });
                          }}
                        />
                      </StyledCell>
                    )}
                  </StyledRow>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
}
