import React from 'react';

import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

interface Props {
  maxDigits?: number;
  decimalPlaces?: number;
  onAccept: (
    // eslint-disable-next-line no-unused-vars
    value: unknown
  ) => void;
}

export default function AcceptableTextField(props: TextFieldProps & Props) {
  const { onAccept, ..._props } = props;
  const [value, setValue] = React.useState(props.value);
  const [acceptTimeout, setAcceptTimeout] = React.useState<any>(null);
  const isEditing = value !== props.value;

  const handleAcceptClick = () => {
    clearTimeout(acceptTimeout);
    return isEditing && onAccept(value);
  };

  const handleBlur = () =>
    setAcceptTimeout(setTimeout(() => setValue(props.value), 300));

  return (
    <TextField
      {..._props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => e.key === 'Enter' && handleAcceptClick()}
      InputProps={{
        onBlur: handleBlur,
        endAdornment: isEditing && (
          <IconButton
            size="small"
            sx={{ mr: '-10px' }}
            onClick={handleAcceptClick}
          >
            <CheckCircle />
          </IconButton>
        ),
      }}
    />
  );
}
