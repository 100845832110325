import { UUID } from 'crypto';

/* eslint-disable no-unused-vars */

type ReportId = number;
type QuestionnaireKey = string;
type QuestionId = UUID;

export interface IndicatorSchema {
  id: UUID;
  key: string;
  title: string;
  questions_count: number;
  color: string;
}

export interface AnsweredQuestionsSchema {
  [key: ReportId]: {
    [key: QuestionnaireKey]: QuestionId[];
  };
}

export interface IndicatorResponsibility {
  id: number;
  esg_questionnaire_id: UUID;
  app_user_id: number;
  deadline: string;
  report_id: number;
}

export enum QuestionnaireMethodology {
  ORIGINAL_QV3_2023_2024 = 'ORIGINAL_QV3_2023_2024',
  EFRAG_XBRL_202409 = 'EFRAG_XBRL_202409',
}
