import React from 'react';
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import { DecimalWithUnitSchema, UniversalField } from '../types';
import { useTranslation } from 'react-i18next';
import { StyledTitle } from '../../index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { useQv3Enums } from '../../../hooks';
import { HOVER_COLOR } from '../../../helpers';

export default function DecimalWithUnitHorizontal(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value: objValue, onChange, errors, disabled } = props;
  const {
    title,
    label,
    placeholder,
    required,
    theme_color: themeColor,
  } = field;

  const { value, unit } = (objValue as DecimalWithUnitSchema) || {};
  const { qv3Enum } = useQv3Enums(field.enum);

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.value || errors?.unit;
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (v: DecimalWithUnitSchema) => {
    // required field
    if (required && !v) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (v: DecimalWithUnitSchema) => {
    _validate(v);
    onChange(v);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange({ value: e.target.value, unit });

  const handleUnitChange = (e: SelectChangeEvent<string>) =>
    handleChange({ unit: e.target.value, value });

  // not showing the label when placeholder is set
  // except when the value is set (placeholder is covered by value anyways)
  const _label = !value && !!placeholder ? null : label;
  const unitLabel = t('questionnaireV3.fieldTypes.decimal_with_unit.unitLabel');

  return (
    <>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      <StyledFlex sx={{ gap: '5px', mb: '32px' }}>
        <TextField
          fullWidth
          multiline
          value={value ?? ''}
          label={_label}
          onChange={handleValueChange}
          error={!!errorMessage}
          helperText={errorMessage}
          disabled={disabled}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: !!value ? HOVER_COLOR : 'inherit',
            },
          }}
        />
        <FormControl
          sx={{
            minWidth: '140px',
            mb: !!errorMessage ? '22px' : 'default',
          }}
        >
          <InputLabel id={field.key}>{unitLabel}</InputLabel>
          <Select
            sx={{
              height: '57px',
              backgroundColor: !!unit ? HOVER_COLOR : 'inherit',
            }}
            labelId={field.key}
            label={unitLabel}
            value={unit ?? ''}
            onChange={handleUnitChange}
            error={!!errorMessage}
          >
            {qv3Enum.options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledFlex>
    </>
  );
}
