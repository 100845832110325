import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { StyledDescription, StyledPanel } from 'src/Esg/styles';
import SelectingStakeholdersTable from './SelectingStakeholdersTable';
import { useSidebar } from 'src/views/components/Sidebar/hooks';

export default function SelectingStakeHolders() {
  const { t } = useTranslation();
  const { closeSidebar, openSidebar } = useSidebar();

  React.useEffect(() => {
    closeSidebar();
    return () => openSidebar();
  }, []);

  return (
    <StyledDescription>
      <Typography sx={{ mb: '10px' }}>
        <Trans i18nKey="esg.dma.selectingStakeholders.subtitle01" />
      </Typography>
      <ol style={{ paddingLeft: '20px' }}>
        <li>
          <Trans i18nKey="esg.dma.selectingStakeholders.subtitle02" />
        </li>
        <li>
          <Trans i18nKey="esg.dma.selectingStakeholders.subtitle03" />
        </li>
      </ol>
      <br />
      <Trans i18nKey="esg.dma.selectingStakeholders.subtitle04" />
      <br /> <br />
      <Trans i18nKey="esg.dma.selectingStakeholders.subtitle05" />
      <br /> <br />
      <Trans i18nKey="esg.dma.selectingStakeholders.subtitle06" />
      <br /> <br />
      <Trans i18nKey="esg.dma.selectingStakeholders.subtitle07" />
      <br /> <br />
      <Trans i18nKey="esg.dma.selectingStakeholders.subtitle08" />
      <br /> <br />
      <Typography sx={{ mb: '10px' }}>
        {t('esg.dma.selectingStakeholders.justification')}
      </Typography>
      <br />
      <StyledPanel style={{ minWidth: '1350px' }}>
        <SelectingStakeholdersTable />
      </StyledPanel>
    </StyledDescription>
  );
}
