import React from 'react';
import {
  ListItem,
  ListItemIcon,
  TableCell,
  TableCellProps,
  Typography,
} from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';

interface Props {
  text: string;
  collapsed: boolean;
  // eslint-disable-next-line no-unused-vars
  setCollapsed: (collapsed: boolean) => void;
}

export default function CollapsableButtonCell(props: TableCellProps & Props) {
  const { text, collapsed, setCollapsed, ..._props } = props;
  const handleCollapseClick = () => setCollapsed(!collapsed);
  const CollapseIcon = collapsed ? KeyboardArrowRight : KeyboardArrowDown;
  return (
    <TableCell
      {..._props}
      onClick={handleCollapseClick}
      sx={{
        cursor: 'pointer',
        ':hover': { bgcolor: '#eee' },
        ..._props.sx,
      }}
    >
      <ListItem>
        <ListItemIcon sx={{ minWidth: '32px' }}>
          <CollapseIcon />
        </ListItemIcon>
        <Typography variant="h6">{text}</Typography>
      </ListItem>
    </TableCell>
  );
}
