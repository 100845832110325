import React from 'react';
import { Table, TableBody } from '@mui/material';

import { useDmaHierarchy } from '../../hooks';
import Head from './Head';
import TitleRow from './Rows/TitleRow';
import { StyledPanel } from 'src/Esg/styles';

export default function Step3Table() {
  const { stages23Hierarchy } = useDmaHierarchy();
  return (
    <StyledPanel>
      <Table>
        <Head />
        <TableBody>
          {Object.entries(stages23Hierarchy).map(([title, data]) => (
            <TitleRow key={title} title={title} data={data} />
          ))}
        </TableBody>
      </Table>
    </StyledPanel>
  );
}
