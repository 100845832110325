export const units = [
  ['liters', 'liters'],
  ['tons', 'tons'],
  ['cubic_meters', 'cubic_meters'],
  ['kwh_net_cv', 'kwh_net_cv'],
  ['kwh_gross_cv', 'kwh_gross_cv'],
  ['mwh_net_cv', 'mwh_net_cv'],
  ['mwh_gross_cv', 'mwh_gross_cv'],
];

export const energy_units = [
  ['kwh', 'kwh'],
  ['mwh', 'mwh'],
  ['gj', 'gj'],
];

export const energy_heat_units = [
  ...energy_units,
  ['heat_kwh', 'energy.heat_kwh'],
  ['heat_mwh', 'energy.heat_mwh'],
  ['heat_gj', 'energy.heat_gj'],
];

// we can't use the above, because our Selects and Autocompletes work differently =)
export const energy_units_freesolo = [
  ['kWh', 'dropdowns.energy.kwh'],
  ['MWh', 'dropdowns.energy.mwh'],
  ['GJ', 'dropdowns.energy.gj'],
];

export const energy_heat_units_freesolo = [
  ...energy_units_freesolo,
  ['heat_kWh', 'dropdowns.energy.heat_kwh'],
  ['heat_MWh', 'dropdowns.energy.heat_mwh'],
  ['heat_GJ', 'dropdowns.energy.heat_gj'],
];

export const type_of_builidng = [
  ['office', 'office'],
  ['commerce', 'commerce'],
  ['housing', 'housing'],
  ['technical', 'technical'],
  ['warehouse', 'warehouse'],
];

export const unit_uses_years = [
  ['uses', 'uses'],
  ['years', 'years'],
];

export const year_of_construction = [
  ['before_1994', 'before_1994'],
  ['years_1994_1998', 'years_1994_1998'],
  ['years_1999_2008', 'years_1999_2008'],
  ['years_2009_2013', 'years_2009_2013'],
  ['years_2014_2016', 'years_2014_2016'],
  ['years_2017_2018', 'years_2017_2018'],
  ['years_2019_2020', 'years_2019_2020'],
  ['after_2020', 'after_2020'],
];

export const choices_without_others = [
  [
    'gaseous_fuels_title',
    [
      ['gaseous_fuel.butane', 'gaseous_fuel.butane'],
      ['gaseous_fuel.cng', 'gaseous_fuel.cng'],
      ['gaseous_fuel.lng', 'gaseous_fuel.lng'],
      ['gaseous_fuel.lpg', 'gaseous_fuel.lpg'],
      ['gaseous_fuel.natural_gas', 'gaseous_fuel.natural_gas'],
      [
        'gaseous_fuel.natural_gas_100_pct_mineral_blend',
        'gaseous_fuel.natural_gas_100_pct_mineral_blend',
      ],
      ['gaseous_fuel.other_petroleum_gas', 'gaseous_fuel.other_petroleum_gas'],
      ['gaseous_fuel.propane', 'gaseous_fuel.propane'],
    ],
  ],
  [
    'liquid_fuels_title',
    [
      ['liquid_fules.aviation_spirit', 'liquid_fules.aviation_spirit'],
      [
        'liquid_fules.aviation_turbine_fuel',
        'liquid_fules.aviation_turbine_fuel',
      ],
      ['liquid_fules.burning_oil', 'liquid_fules.burning_oil'],
      [
        'liquid_fules.diesel_average_biofuel_blend',
        'liquid_fules.diesel_average_biofuel_blend',
      ],
      [
        'liquid_fules.diesel_100_pct_mineral',
        'liquid_fules.diesel_100_pct_mineral',
      ],
      ['liquid_fules.fuel_oil', 'liquid_fules.fuel_oil'],
      ['liquid_fules.gas_oil', 'liquid_fules.gas_oil'],
      ['liquid_fules.lubricants', 'liquid_fules.lubricants'],
      ['liquid_fules.naphtha', 'liquid_fules.naphtha'],
      [
        'liquid_fules.petrol_average_biofuel_blend',
        'liquid_fules.petrol_average_biofuel_blend',
      ],
      [
        'liquid_fules.petrol_100_pct_mineral',
        'liquid_fules.petrol_100_pct_mineral',
      ],
      [
        'liquid_fules.processed_fuel_oils_residual_oil',
        'liquid_fules.processed_fuel_oils_residual_oil',
      ],
      [
        'liquid_fules.processed_fuel_oils_distillate_oil',
        'liquid_fules.processed_fuel_oils_distillate_oil',
      ],
      ['liquid_fules.waste_oils', 'liquid_fules.waste_oils'],
      ['liquid_fules.marine_gas_oil', 'liquid_fules.marine_gas_oil'],
      ['liquid_fules.marine_fuel_oil', 'liquid_fules.marine_fuel_oil'],
    ],
  ],
  [
    'solid_fuels_title',
    [
      ['solid_fuels.coal_industrial', 'solid_fuels.coal_industrial'],
      [
        'solid_fuels.coal_electricity_generation',
        'solid_fuels.coal_electricity_generation',
      ],
      ['solid_fuels.coal_domestic', 'solid_fuels.coal_domestic'],
      ['solid_fuels.coking_coal', 'solid_fuels.coking_coal'],
      ['solid_fuels.petroleum_coke', 'solid_fuels.petroleum_coke'],
    ],
  ],
];

export const choices = [
  ['others', [['other.manual_factor', 'other.manual_factor']]],
  ...choices_without_others,
];

export const mobileChoices = [
  ['other_manual_factor', 'other_manual_factor'],
  ['cng', 'cng'],
  ['lng', 'lng'],
  ['lpg', 'lpg'],
  ['natural_gas', 'natural_gas'],
  ['natural_gas_100_pct_mineral_blend', 'natural_gas_100_pct_mineral_blend'],
  ['aviation_spirit', 'aviation_spirit'],
  ['aviation_turbine_fuel', 'aviation_turbine_fuel'],
  ['burning_oil', 'burning_oil'],
  ['diesel_average_biofuel_blend', 'diesel_average_biofuel_blend'],
  ['diesel_100_pct_mineral_diesel', 'diesel_100_pct_mineral_diesel'],
  ['fuel_oil', 'fuel_oil'],
  ['gas_oil', 'gas_oil'],
  ['lubricants', 'lubricants'],
  ['naphtha', 'naphtha'],
  ['petrol_average_biofuel_blend', 'petrol_average_biofuel_blend'],
  ['petrol_100_pct_mineral_petrol', 'petrol_100_pct_mineral_petrol'],
  ['processed_fuel_oils_residual_oil', 'processed_fuel_oils_residual_oil'],
  ['processed_fuel_oils_distillate_oil', 'processed_fuel_oils_distillate_oil'],
  ['waste_oils', 'waste_oils'],
  ['marine_gas_oil', 'marine_gas_oil'],
  ['marine_fuel_oil', 'marine_fuel_oil'],
];

export const fugutive_emissions_choices = [
  ['others', [['other.manual_factor', 'other.manual_factor']]],
  [
    'kyoto_standard',
    [
      ['kyoto.carbon_dioxide', 'kyoto.carbon_dioxide'],
      ['kyoto.methane', 'kyoto.methane'],
      ['kyoto.nitrous_oxide', 'kyoto.nitrous_oxide'],
      ['kyoto.hfc_23', 'kyoto.hfc_23'],
      ['kyoto.hfc_32', 'kyoto.hfc_32'],
      ['kyoto.hfc_41', 'kyoto.hfc_41'],
      ['kyoto.hfc_125', 'kyoto.hfc_125'],
      ['kyoto.hfc_134', 'kyoto.hfc_134'],
      ['kyoto.hfc_134a', 'kyoto.hfc_134a'],
      ['kyoto.hfc_143', 'kyoto.hfc_143'],
      ['kyoto.hfc_143a', 'kyoto.hfc_143a'],
      ['kyoto.hfc_152a', 'kyoto.hfc_152a'],
      ['kyoto.hfc_227ea', 'kyoto.hfc_227ea'],
      ['kyoto.hfc_236fa', 'kyoto.hfc_236fa'],
      ['kyoto.hfc_245fa', 'kyoto.hfc_245fa'],
      ['kyoto.hfc_43_i0mee', 'kyoto.hfc_43_i0mee'],
      ['kyoto.perfluoromethane_pfc_14', 'kyoto.perfluoromethane_pfc_14'],
      ['kyoto.perfluoroethane_pfc_116', 'kyoto.perfluoroethane_pfc_116'],
      ['kyoto.perfluoropropane_pfc_218', 'kyoto.perfluoropropane_pfc_218'],
      [
        'kyoto.perfluorocyclobutane_pfc_318',
        'kyoto.perfluorocyclobutane_pfc_318',
      ],
      ['kyoto.perfluorobutane_pfc_3_1_10', 'kyoto.perfluorobutane_pfc_3_1_10'],
      [
        'kyoto.perfluoropentane_pfc_4_1_12',
        'kyoto.perfluoropentane_pfc_4_1_12',
      ],
      ['kyoto.perfluorohexane_pfc_5_1_14', 'kyoto.perfluorohexane_pfc_5_1_14'],
      ['kyoto.pfc_9_1_18', 'kyoto.pfc_9_1_18'],
      ['kyoto.perfluorocyclopropane', 'kyoto.perfluorocyclopropane'],
      ['kyoto.sulphur_hexafluoride_sf6', 'kyoto.sulphur_hexafluoride_sf6'],
      ['kyoto.hfc_152', 'kyoto.hfc_152'],
      ['kyoto.hfc_161', 'kyoto.hfc_161'],
      ['kyoto.hfc_236cb', 'kyoto.hfc_236cb'],
      ['kyoto.hfc_236ea', 'kyoto.hfc_236ea'],
      ['kyoto.hfc_245ca', 'kyoto.hfc_245ca'],
      ['kyoto.hfc_365mfc', 'kyoto.hfc_365mfc'],
      ['kyoto.nitrogen_trifluoride', 'kyoto.nitrogen_trifluoride'],
    ],
  ],
  [
    'blends',
    [
      ['blends.R401A', 'blends.R401A'],
      ['blends.R401B', 'blends.R401B'],
      ['blends.R401C', 'blends.R401C'],
      ['blends.R402A', 'blends.R402A'],
      ['blends.R402B', 'blends.R402B'],
      ['blends.R403A', 'blends.R403A'],
      ['blends.R403B', 'blends.R403B'],
      ['blends.R404A', 'blends.R404A'],
      ['blends.R405A', 'blends.R405A'],
      ['blends.R407A', 'blends.R407A'],
      ['blends.R407B', 'blends.R407B'],
      ['blends.R407C', 'blends.R407C'],
      ['blends.R407D', 'blends.R407D'],
      ['blends.R407E', 'blends.R407E'],
      ['blends.R407F', 'blends.R407F'],
      ['blends.R408A', 'blends.R408A'],
      ['blends.R410A', 'blends.R410A'],
      ['blends.R410B', 'blends.R410B'],
      ['blends.R411A', 'blends.R411A'],
      ['blends.R411B', 'blends.R411B'],
      ['blends.R412A', 'blends.R412A'],
      ['blends.R413A', 'blends.R413A'],
      ['blends.R415A', 'blends.R415A'],
      ['blends.R415B', 'blends.R415B'],
      ['blends.R416A', 'blends.R416A'],
      ['blends.R417A', 'blends.R417A'],
      ['blends.R417B', 'blends.R417B'],
      ['blends.R417C', 'blends.R417C'],
      ['blends.R418A', 'blends.R418A'],
      ['blends.R419A', 'blends.R419A'],
      ['blends.R419B', 'blends.R419B'],
      ['blends.R420A', 'blends.R420A'],
      ['blends.R421A', 'blends.R421A'],
      ['blends.R421B', 'blends.R421B'],
      ['blends.R422A', 'blends.R422A'],
      ['blends.R422B', 'blends.R422B'],
      ['blends.R422C', 'blends.R422C'],
      ['blends.R422D', 'blends.R422D'],
      ['blends.R422E', 'blends.R422E'],
      ['blends.R423A', 'blends.R423A'],
      ['blends.R424A', 'blends.R424A'],
      ['blends.R425A', 'blends.R425A'],
      ['blends.R426A', 'blends.R426A'],
      ['blends.R427A', 'blends.R427A'],
      ['blends.R428A', 'blends.R428A'],
      ['blends.R429A', 'blends.R429A'],
      ['blends.R430A', 'blends.R430A'],
      ['blends.R431A', 'blends.R431A'],
      ['blends.R434A', 'blends.R434A'],
      ['blends.R435A', 'blends.R435A'],
      ['blends.R437A', 'blends.R437A'],
      ['blends.R438A', 'blends.R438A'],
      ['blends.R439A', 'blends.R439A'],
      ['blends.R440A', 'blends.R440A'],
      ['blends.R442A', 'blends.R442A'],
      ['blends.R444A', 'blends.R444A'],
      ['blends.R445A', 'blends.R445A'],
      ['blends.R500', 'blends.R500'],
      ['blends.R503', 'blends.R503'],
      ['blends.R504', 'blends.R504'],
      ['blends.R507A', 'blends.R507A'],
      ['blends.R508A', 'blends.R508A'],
      ['blends.R508B', 'blends.R508B'],
      ['blends.R509A', 'blends.R509A'],
      ['blends.R511A', 'blends.R511A'],
      ['blends.R512A', 'blends.R512A'],
    ],
  ],
];

const waste_ewc = {
  'ewc.01_01': ['ewc.01_01_01', 'ewc.01_01_02', 'ewc.01_01_80'],
  'ewc.01_03': [
    'ewc.01_03_04',
    'ewc.01_03_05',
    'ewc.01_03_06',
    'ewc.01_03_07',
    'ewc.01_03_08',
    'ewc.01_03_09',
    'ewc.01_03_80',
    'ewc.01_03_81',
    'ewc.01_03_99',
  ],
  'ewc.01_04': [
    'ewc.01_04_07',
    'ewc.01_04_08',
    'ewc.01_04_09',
    'ewc.01_04_10',
    'ewc.01_04_11',
    'ewc.01_04_12',
    'ewc.01_04_13',
    'ewc.01_04_80',
    'ewc.01_04_81',
    'ewc.01_04_82',
    'ewc.01_04_83',
    'ewc.01_04_84',
    'ewc.01_04_85',
    'ewc.01_04_99',
  ],
  'ewc.01_05': [
    'ewc.01_05_04',
    'ewc.01_05_05',
    'ewc.01_05_06',
    'ewc.01_05_07',
    'ewc.01_05_08',
    'ewc.01_05_99',
  ],
  'ewc.02_01': [
    'ewc.02_01_01',
    'ewc.02_01_02',
    'ewc.02_01_03',
    'ewc.02_01_04',
    'ewc.02_01_06',
    'ewc.02_01_07',
    'ewc.02_01_08',
    'ewc.02_01_09',
    'ewc.02_01_10',
    'ewc.02_01_80',
    'ewc.02_01_81',
    'ewc.02_01_82',
    'ewc.02_01_83',
    'ewc.02_01_99',
  ],
  'ewc.02_02': [
    'ewc.02_02_01',
    'ewc.02_02_02',
    'ewc.02_02_03',
    'ewc.02_02_04',
    'ewc.02_02_04_br',
    'ewc.02_02_80',
    'ewc.02_02_81',
    'ewc.02_02_82',
    'ewc.02_02_99',
  ],
  'ewc.02_03': [
    'ewc.02_03_01',
    'ewc.02_03_02',
    'ewc.02_03_03',
    'ewc.02_03_04',
    'ewc.02_03_05',
    'ewc.02_03_80',
    'ewc.02_03_81',
    'ewc.02_03_82',
    'ewc.02_03_99',
  ],
  'ewc.02_04': [
    'ewc.02_04_01',
    'ewc.02_04_02',
    'ewc.02_04_03',
    'ewc.02_04_80',
    'ewc.02_04_99',
  ],
  'ewc.02_05': ['ewc.02_05_01', 'ewc.02_05_02', 'ewc.02_05_80', 'ewc.02_05_99'],
  'ewc.02_06': [
    'ewc.02_06_01',
    'ewc.02_06_02',
    'ewc.02_06_03',
    'ewc.02_06_80',
    'ewc.02_06_99',
  ],
  'ewc.02_07': [
    'ewc.02_07_01',
    'ewc.02_07_02',
    'ewc.02_07_03',
    'ewc.02_07_04',
    'ewc.02_07_05',
    'ewc.02_07_80',
    'ewc.02_07_99',
  ],
  'ewc.03_01': [
    'ewc.03_01_01',
    'ewc.03_01_04',
    'ewc.03_01_05',
    'ewc.03_01_80',
    'ewc.03_01_81',
    'ewc.03_01_82',
    'ewc.03_01_99',
  ],
  'ewc.03_02': [
    'ewc.03_02_01',
    'ewc.03_02_02',
    'ewc.03_02_03',
    'ewc.03_02_04',
    'ewc.03_02_05',
    'ewc.03_02_99',
  ],
  'ewc.03_03': [
    'ewc.03_03_01',
    'ewc.03_03_02',
    'ewc.03_03_05',
    'ewc.03_03_07',
    'ewc.03_03_08',
    'ewc.03_03_09',
    'ewc.03_03_10',
    'ewc.03_03_11',
    'ewc.03_03_80',
    'ewc.03_03_81',
    'ewc.03_03_99',
  ],
  'ewc.04_01': [
    'ewc.04_01_01',
    'ewc.04_01_02',
    'ewc.04_01_03',
    'ewc.04_01_04',
    'ewc.04_01_05',
    'ewc.04_01_06',
    'ewc.04_01_07',
    'ewc.04_01_08',
    'ewc.04_01_09',
    'ewc.04_01_99',
  ],
  'ewc.04_02': [
    'ewc.04_02_09',
    'ewc.04_02_10',
    'ewc.04_02_14',
    'ewc.04_02_15',
    'ewc.04_02_16',
    'ewc.04_02_17',
    'ewc.04_02_19',
    'ewc.04_02_20',
    'ewc.04_02_21',
    'ewc.04_02_22',
    'ewc.04_02_80',
    'ewc.04_02_99',
  ],
  'ewc.05': ['ewc.05_01'],
  'ewc.05_01': [
    'ewc.05_01_02',
    'ewc.05_01_03',
    'ewc.05_01_04',
    'ewc.05_01_05',
    'ewc.05_01_06',
    'ewc.05_01_07',
    'ewc.05_01_08',
    'ewc.05_01_09',
    'ewc.05_01_10',
    'ewc.05_01_11',
    'ewc.05_01_12',
    'ewc.05_01_13',
    'ewc.05_01_14',
    'ewc.05_01_15',
    'ewc.05_01_16',
    'ewc.05_01_17',
    'ewc.05_01_99',
  ],
  'ewc.05_06': [
    'ewc.05_06_01',
    'ewc.05_06_03',
    'ewc.05_06_04',
    'ewc.05_06_80',
    'ewc.05_06_99',
  ],
  'ewc.05_07': ['ewc.05_07_01', 'ewc.05_07_02', 'ewc.05_07_99'],
  'ewc.06_01': [
    'ewc.06_01_01',
    'ewc.06_01_02',
    'ewc.06_01_03',
    'ewc.06_01_04',
    'ewc.06_01_05',
    'ewc.06_01_06',
    'ewc.06_01_99',
  ],
  'ewc.06_02': [
    'ewc.06_02_01',
    'ewc.06_02_03',
    'ewc.06_02_04',
    'ewc.06_02_05',
    'ewc.06_02_99',
  ],
  'ewc.06_03': [
    'ewc.06_03_11',
    'ewc.06_03_13',
    'ewc.06_03_14',
    'ewc.06_03_15',
    'ewc.06_03_16',
    'ewc.06_03_99',
  ],
  'ewc.06_04': ['ewc.06_04_03', 'ewc.06_04_04', 'ewc.06_04_05', 'ewc.06_04_99'],
  'ewc.06_05': ['ewc.06_05_02', 'ewc.06_05_03'],
  'ewc.06_06': ['ewc.06_06_02', 'ewc.06_06_03', 'ewc.06_06_99'],
  'ewc.06_07': [
    'ewc.06_07_01',
    'ewc.06_07_02',
    'ewc.06_07_03',
    'ewc.06_07_04',
    'ewc.06_07_99',
  ],
  'ewc.06_08': ['ewc.06_08_02', 'ewc.06_08_99'],
  'ewc.06_09': [
    'ewc.06_09_02',
    'ewc.06_09_03',
    'ewc.06_09_04',
    'ewc.06_09_80',
    'ewc.06_09_81',
    'ewc.06_09_99',
  ],
  'ewc.06_10': ['ewc.06_10_02', 'ewc.06_10_99'],
  'ewc.06_11': [
    'ewc.06_11_01',
    'ewc.06_11_80',
    'ewc.06_11_81',
    'ewc.06_11_82',
    'ewc.06_11_83',
    'ewc.06_11_99',
  ],
  'ewc.06_13': [
    'ewc.06_13_01',
    'ewc.06_13_02',
    'ewc.06_13_03',
    'ewc.06_13_04',
    'ewc.06_13_05',
    'ewc.06_13_99',
  ],
  'ewc.07_01': [
    'ewc.07_01_01',
    'ewc.07_01_03',
    'ewc.07_01_04',
    'ewc.07_01_07',
    'ewc.07_01_08',
    'ewc.07_01_09',
    'ewc.07_01_10',
    'ewc.07_01_11',
    'ewc.07_01_12',
    'ewc.07_01_80',
    'ewc.07_01_99',
  ],
  'ewc.07_02': [
    'ewc.07_02_01',
    'ewc.07_02_03',
    'ewc.07_02_04',
    'ewc.07_02_07',
    'ewc.07_02_08',
    'ewc.07_02_09',
    'ewc.07_02_10',
    'ewc.07_02_11',
    'ewc.07_02_12',
    'ewc.07_02_13',
    'ewc.07_02_14',
    'ewc.07_02_15',
    'ewc.07_02_16',
    'ewc.07_02_17',
    'ewc.07_02_80',
    'ewc.07_02_99',
  ],
  'ewc.07_03': [
    'ewc.07_03_01',
    'ewc.07_03_03',
    'ewc.07_03_04',
    'ewc.07_03_07',
    'ewc.07_03_08',
    'ewc.07_03_09',
    'ewc.07_03_10',
    'ewc.07_03_11',
    'ewc.07_03_12',
    'ewc.07_03_99',
  ],
  'ewc.07_04': [
    'ewc.07_04_01',
    'ewc.07_04_03',
    'ewc.07_04_04',
    'ewc.07_04_07',
    'ewc.07_04_08',
    'ewc.07_04_09',
    'ewc.07_04_10',
    'ewc.07_04_11',
    'ewc.07_04_12',
    'ewc.07_04_13',
    'ewc.07_04_80',
    'ewc.07_04_81',
    'ewc.07_04_99',
  ],
  'ewc.07_05': [
    'ewc.07_05_01',
    'ewc.07_05_03',
    'ewc.07_05_04',
    'ewc.07_05_07',
    'ewc.07_05_08',
    'ewc.07_05_09',
    'ewc.07_05_10',
    'ewc.07_05_11',
    'ewc.07_05_12',
    'ewc.07_05_13',
    'ewc.07_05_14',
    'ewc.07_05_80',
    'ewc.07_05_81',
    'ewc.07_05_99',
  ],
  'ewc.07_06': [
    'ewc.07_06_01',
    'ewc.07_06_03',
    'ewc.07_06_04',
    'ewc.07_06_07',
    'ewc.07_06_08',
    'ewc.07_06_09',
    'ewc.07_06_10',
    'ewc.07_06_11',
    'ewc.07_06_12',
    'ewc.07_06_80',
    'ewc.07_06_81',
    'ewc.07_06_99',
  ],
  'ewc.07_07': [
    'ewc.07_07_01',
    'ewc.07_07_03',
    'ewc.07_07_04',
    'ewc.07_07_07',
    'ewc.07_07_08',
    'ewc.07_07_09',
    'ewc.07_07_10',
    'ewc.07_07_11',
    'ewc.07_07_12',
    'ewc.07_07_99',
  ],
  'ewc.08_01': [
    'ewc.08_01_11',
    'ewc.08_01_12',
    'ewc.08_01_13',
    'ewc.08_01_14',
    'ewc.08_01_15',
    'ewc.08_01_16',
    'ewc.08_01_17',
    'ewc.08_01_18',
    'ewc.08_01_19',
    'ewc.08_01_20',
    'ewc.08_01_21',
    'ewc.08_01_99',
  ],
  'ewc.08_02': ['ewc.08_02_01', 'ewc.08_02_02', 'ewc.08_02_03', 'ewc.08_02_99'],
  'ewc.08_03': [
    'ewc.08_03_07',
    'ewc.08_03_08',
    'ewc.08_03_12',
    'ewc.08_03_13',
    'ewc.08_03_14',
    'ewc.08_03_15',
    'ewc.08_03_16',
    'ewc.08_03_17',
    'ewc.08_03_18',
    'ewc.08_03_19',
    'ewc.08_03_80',
    'ewc.08_03_99',
  ],
  'ewc.08_04': [
    'ewc.08_04_09',
    'ewc.08_04_10',
    'ewc.08_04_11',
    'ewc.08_04_12',
    'ewc.08_04_13',
    'ewc.08_04_14',
    'ewc.08_04_15',
    'ewc.08_04_16',
    'ewc.08_04_17',
    'ewc.08_04_99',
  ],
  'ewc.08_05': ['ewc.08_05_01'],
  'ewc.09_01': [
    'ewc.09_01_01',
    'ewc.09_01_02',
    'ewc.09_01_03',
    'ewc.09_01_04',
    'ewc.09_01_05',
    'ewc.09_01_06',
    'ewc.09_01_07',
    'ewc.09_01_08',
    'ewc.09_01_10',
    'ewc.09_01_11',
    'ewc.09_01_12',
    'ewc.09_01_13',
    'ewc.09_01_80',
    'ewc.09_01_99',
  ],
  'ewc.10_01': [
    'ewc.10_01_01',
    'ewc.10_01_02',
    'ewc.10_01_03',
    'ewc.10_01_04',
    'ewc.10_01_05',
    'ewc.10_01_07',
    'ewc.10_01_09',
    'ewc.10_01_13',
    'ewc.10_01_14',
    'ewc.10_01_15',
    'ewc.10_01_16',
    'ewc.10_01_17',
    'ewc.10_01_18',
    'ewc.10_01_19',
    'ewc.10_01_20',
    'ewc.10_01_21',
    'ewc.10_01_22',
    'ewc.10_01_23',
    'ewc.10_01_24',
    'ewc.10_01_25',
    'ewc.10_01_26',
    'ewc.10_01_80',
    'ewc.10_01_81',
    'ewc.10_01_82',
    'ewc.10_01_99',
  ],
  'ewc.10_02': [
    'ewc.10_02_01',
    'ewc.10_02_02',
    'ewc.10_02_07',
    'ewc.10_02_08',
    'ewc.10_02_10',
    'ewc.10_02_11',
    'ewc.10_02_12',
    'ewc.10_02_13',
    'ewc.10_02_14',
    'ewc.10_02_15',
    'ewc.10_02_80',
    'ewc.10_02_81',
    'ewc.10_02_99',
  ],
  'ewc.10_03': [
    'ewc.10_03_02',
    'ewc.10_03_04',
    'ewc.10_03_05',
    'ewc.10_03_08',
    'ewc.10_03_09',
    'ewc.10_03_15',
    'ewc.10_03_16',
    'ewc.10_03_17',
    'ewc.10_03_18',
    'ewc.10_03_19',
    'ewc.10_03_20',
    'ewc.10_03_21',
    'ewc.10_03_22',
    'ewc.10_03_23',
    'ewc.10_03_24',
    'ewc.10_03_25',
    'ewc.10_03_26',
    'ewc.10_03_27',
    'ewc.10_03_28',
    'ewc.10_03_29',
    'ewc.10_03_30',
    'ewc.10_03_99',
  ],
  'ewc.10_04': [
    'ewc.10_04_01',
    'ewc.10_04_02',
    'ewc.10_04_03',
    'ewc.10_04_04',
    'ewc.10_04_05',
    'ewc.10_04_06',
    'ewc.10_04_07',
    'ewc.10_04_09',
    'ewc.10_04_10',
    'ewc.10_04_99',
  ],
  'ewc.10_05': [
    'ewc.10_05_01',
    'ewc.10_05_03',
    'ewc.10_05_04',
    'ewc.10_05_05',
    'ewc.10_05_06',
    'ewc.10_05_08',
    'ewc.10_05_09',
    'ewc.10_05_10',
    'ewc.10_05_11',
    'ewc.10_05_80',
    'ewc.10_05_99',
  ],
  'ewc.10_06': [
    'ewc.10_06_01',
    'ewc.10_06_02',
    'ewc.10_06_03',
    'ewc.10_06_04',
    'ewc.10_06_06',
    'ewc.10_06_07',
    'ewc.10_06_09',
    'ewc.10_06_10',
    'ewc.10_06_80',
    'ewc.10_06_99',
  ],
  'ewc.10_07': [
    'ewc.10_07_01',
    'ewc.10_07_02',
    'ewc.10_07_03',
    'ewc.10_07_04',
    'ewc.10_07_05',
    'ewc.10_07_07',
    'ewc.10_07_08',
    'ewc.10_07_99',
  ],
  'ewc.10_08': [
    'ewc.10_08_04',
    'ewc.10_08_08',
    'ewc.10_08_09',
    'ewc.10_08_10',
    'ewc.10_08_11',
    'ewc.10_08_12',
    'ewc.10_08_13',
    'ewc.10_08_14',
    'ewc.10_08_15',
    'ewc.10_08_16',
    'ewc.10_08_17',
    'ewc.10_08_18',
    'ewc.10_08_19',
    'ewc.10_08_20',
    'ewc.10_08_99',
  ],
  'ewc.10_09': [
    'ewc.10_09_03',
    'ewc.10_09_05',
    'ewc.10_09_06',
    'ewc.10_09_07',
    'ewc.10_09_08',
    'ewc.10_09_09',
    'ewc.10_09_10',
    'ewc.10_09_11',
    'ewc.10_09_12',
    'ewc.10_09_13',
    'ewc.10_09_14',
    'ewc.10_09_15',
    'ewc.10_09_16',
    'ewc.10_09_80',
    'ewc.10_09_99',
  ],
  'ewc.10_10': [
    'ewc.10_10_03',
    'ewc.10_10_05',
    'ewc.10_10_06',
    'ewc.10_10_07',
    'ewc.10_10_08',
    'ewc.10_10_09',
    'ewc.10_10_10',
    'ewc.10_10_11',
    'ewc.10_10_12',
    'ewc.10_10_13',
    'ewc.10_10_14',
    'ewc.10_10_15',
    'ewc.10_10_16',
    'ewc.10_10_99',
  ],
  'ewc.10_11': [
    'ewc.10_11_03',
    'ewc.10_11_05',
    'ewc.10_11_09',
    'ewc.10_11_10',
    'ewc.10_11_11',
    'ewc.10_11_12',
    'ewc.10_11_13',
    'ewc.10_11_14',
    'ewc.10_11_15',
    'ewc.10_11_16',
    'ewc.10_11_17',
    'ewc.10_11_18',
    'ewc.10_11_19',
    'ewc.10_11_20',
    'ewc.10_11_80',
    'ewc.10_11_81',
    'ewc.10_11_99',
  ],
  'ewc.10_12': [
    'ewc.10_12_01',
    'ewc.10_12_03',
    'ewc.10_12_05',
    'ewc.10_12_06',
    'ewc.10_12_08',
    'ewc.10_12_09',
    'ewc.10_12_10',
    'ewc.10_12_11',
    'ewc.10_12_12',
    'ewc.10_12_13',
    'ewc.10_12_99',
  ],
  'ewc.10_13': [
    'ewc.10_13_01',
    'ewc.10_13_04',
    'ewc.10_13_06',
    'ewc.10_13_07',
    'ewc.10_13_09',
    'ewc.10_13_10',
    'ewc.10_13_11',
    'ewc.10_13_12',
    'ewc.10_13_13',
    'ewc.10_13_14',
    'ewc.10_13_80',
    'ewc.10_13_81',
    'ewc.10_13_82',
    'ewc.10_13_99',
  ],
  'ewc.10_14': ['ewc.10_14_01'],
  'ewc.10_80': [
    'ewc.10_80_01',
    'ewc.10_80_02',
    'ewc.10_80_03',
    'ewc.10_80_04',
    'ewc.10_80_05',
    'ewc.10_80_06',
    'ewc.10_80_99',
  ],
  'ewc.11_01': [
    'ewc.11_01_05',
    'ewc.11_01_06',
    'ewc.11_01_07',
    'ewc.11_01_08',
    'ewc.11_01_09',
    'ewc.11_01_10',
    'ewc.11_01_11',
    'ewc.11_01_12',
    'ewc.11_01_13',
    'ewc.11_01_14',
    'ewc.11_01_15',
    'ewc.11_01_16',
    'ewc.11_01_98',
    'ewc.11_01_99',
  ],
  'ewc.11_02': [
    'ewc.11_02_02',
    'ewc.11_02_03',
    'ewc.11_02_05',
    'ewc.11_02_06',
    'ewc.11_02_07',
    'ewc.11_02_99',
  ],
  'ewc.11_03': ['ewc.11_03_01', 'ewc.11_03_02'],
  'ewc.11_05': [
    'ewc.11_05_01',
    'ewc.11_05_02',
    'ewc.11_05_03',
    'ewc.11_05_04',
    'ewc.11_05_99',
  ],
  'ewc.12_01': [
    'ewc.12_01_01',
    'ewc.12_01_02',
    'ewc.12_01_03',
    'ewc.12_01_04',
    'ewc.12_01_05',
    'ewc.12_01_06',
    'ewc.12_01_07',
    'ewc.12_01_08',
    'ewc.12_01_09',
    'ewc.12_01_10',
    'ewc.12_01_12',
    'ewc.12_01_13',
    'ewc.12_01_14',
    'ewc.12_01_15',
    'ewc.12_01_16',
    'ewc.12_01_17',
    'ewc.12_01_18',
    'ewc.12_01_19',
    'ewc.12_01_20',
    'ewc.12_01_21',
    'ewc.12_01_99',
  ],
  'ewc.12_03': ['ewc.12_03_01', 'ewc.12_03_02'],
  'ewc.13_01': [
    'ewc.13_01_01',
    'ewc.13_01_04',
    'ewc.13_01_05',
    'ewc.13_01_09',
    'ewc.13_01_10',
    'ewc.13_01_11',
    'ewc.13_01_12',
    'ewc.13_01_13',
  ],
  'ewc.13_02': [
    'ewc.13_02_04',
    'ewc.13_02_05',
    'ewc.13_02_06',
    'ewc.13_02_07',
    'ewc.13_02_08',
  ],
  'ewc.13_03': [
    'ewc.13_03_01',
    'ewc.13_03_06',
    'ewc.13_03_07',
    'ewc.13_03_08',
    'ewc.13_03_09',
    'ewc.13_03_10',
  ],
  'ewc.13_04': ['ewc.13_04_01', 'ewc.13_04_02', 'ewc.13_04_03'],
  'ewc.13_05': [
    'ewc.13_05_01',
    'ewc.13_05_02',
    'ewc.13_05_03',
    'ewc.13_05_06',
    'ewc.13_05_07',
    'ewc.13_05_08',
  ],
  'ewc.13_07': ['ewc.13_07_01', 'ewc.13_07_02', 'ewc.13_07_03'],
  'ewc.13_08': ['ewc.13_08_01', 'ewc.13_08_02', 'ewc.13_08_80', 'ewc.13_08_99'],
  'ewc.14_06': [
    'ewc.14_06_01',
    'ewc.14_06_02',
    'ewc.14_06_03',
    'ewc.14_06_04',
    'ewc.14_06_05',
  ],
  'ewc.15_01': [
    'ewc.15_01_01',
    'ewc.15_01_02',
    'ewc.15_01_03',
    'ewc.15_01_04',
    'ewc.15_01_05',
    'ewc.15_01_06',
    'ewc.15_01_07',
    'ewc.15_01_09',
    'ewc.15_01_10',
    'ewc.15_01_11',
  ],
  'ewc.15_02': ['ewc.15_02_02', 'ewc.15_02_03'],
  'ewc.16_01': [
    'ewc.16_01_03',
    'ewc.16_01_04',
    'ewc.16_01_06',
    'ewc.16_01_07',
    'ewc.16_01_08',
    'ewc.16_01_09',
    'ewc.16_01_10',
    'ewc.16_01_11',
    'ewc.16_01_12',
    'ewc.16_01_13',
    'ewc.16_01_14',
    'ewc.16_01_15',
    'ewc.16_01_16',
    'ewc.16_01_17',
    'ewc.16_01_18',
    'ewc.16_01_19',
    'ewc.16_01_20',
    'ewc.16_01_21',
    'ewc.16_01_22',
    'ewc.16_01_99',
  ],
  'ewc.16_02': [
    'ewc.16_02_09',
    'ewc.16_02_10',
    'ewc.16_02_11',
    'ewc.16_02_12',
    'ewc.16_02_13',
    'ewc.16_02_14',
    'ewc.16_02_15',
    'ewc.16_02_16',
  ],
  'ewc.16_03': [
    'ewc.16_03_03',
    'ewc.16_03_04',
    'ewc.16_03_05',
    'ewc.16_03_06',
    'ewc.16_03_80',
  ],
  'ewc.16_04': ['ewc.16_04_01', 'ewc.16_04_02', 'ewc.16_04_03'],
  'ewc.16_05': [
    'ewc.16_05_04',
    'ewc.16_05_05',
    'ewc.16_05_06',
    'ewc.16_05_07',
    'ewc.16_05_08',
    'ewc.16_05_09',
  ],
  'ewc.16_06': [
    'ewc.16_06_01',
    'ewc.16_06_02',
    'ewc.16_06_03',
    'ewc.16_06_04',
    'ewc.16_06_05',
    'ewc.16_06_06',
  ],
  'ewc.16_07': ['ewc.16_07_08', 'ewc.16_07_09', 'ewc.16_07_99'],
  'ewc.16_08': [
    'ewc.16_08_01',
    'ewc.16_08_02',
    'ewc.16_08_03',
    'ewc.16_08_04',
    'ewc.16_08_05',
    'ewc.16_08_06',
    'ewc.16_08_07',
  ],
  'ewc.16_09': ['ewc.16_09_01', 'ewc.16_09_02', 'ewc.16_09_03', 'ewc.16_09_04'],
  'ewc.16_10': ['ewc.16_10_01', 'ewc.16_10_02', 'ewc.16_10_03', 'ewc.16_10_04'],
  'ewc.16_11': [
    'ewc.16_11_01',
    'ewc.16_11_02',
    'ewc.16_11_03',
    'ewc.16_11_04',
    'ewc.16_11_05',
    'ewc.16_11_06',
  ],
  'ewc.16_80': ['ewc.16_80_01'],
  'ewc.16_81': ['ewc.16_81_01', 'ewc.16_81_02'],
  'ewc.16_82': ['ewc.16_82_01', 'ewc.16_82_02'],
  'ewc.17_01': [
    'ewc.17_01_01',
    'ewc.17_01_02',
    'ewc.17_01_03',
    'ewc.17_01_06',
    'ewc.17_01_07',
    'ewc.17_01_80',
    'ewc.17_01_81',
    'ewc.17_01_82',
  ],
  'ewc.17_02': ['ewc.17_02_01', 'ewc.17_02_02', 'ewc.17_02_03', 'ewc.17_02_04'],
  'ewc.17_03': ['ewc.17_03_01', 'ewc.17_03_02', 'ewc.17_03_03', 'ewc.17_03_80'],
  'ewc.17_04': [
    'ewc.17_04_01',
    'ewc.17_04_02',
    'ewc.17_04_03',
    'ewc.17_04_04',
    'ewc.17_04_05',
    'ewc.17_04_06',
    'ewc.17_04_07',
    'ewc.17_04_09',
    'ewc.17_04_10',
    'ewc.17_04_11',
  ],
  'ewc.17_05': [
    'ewc.17_05_03',
    'ewc.17_05_04',
    'ewc.17_05_05',
    'ewc.17_05_06',
    'ewc.17_05_07',
    'ewc.17_05_08',
  ],
  'ewc.17_06': ['ewc.17_06_01', 'ewc.17_06_03', 'ewc.17_06_04', 'ewc.17_06_05'],
  'ewc.17_08': ['ewc.17_08_01', 'ewc.17_08_02'],
  'ewc.17_09': ['ewc.17_09_01', 'ewc.17_09_02', 'ewc.17_09_03', 'ewc.17_09_04'],
  'ewc.18_01': [
    'ewc.18_01_01',
    'ewc.18_01_02',
    'ewc.18_01_03',
    'ewc.18_01_04',
    'ewc.18_01_06',
    'ewc.18_01_07',
    'ewc.18_01_08',
    'ewc.18_01_09',
    'ewc.18_01_10',
    'ewc.18_01_80',
    'ewc.18_01_81',
    'ewc.18_01_82',
  ],
  'ewc.18_02': [
    'ewc.18_02_01',
    'ewc.18_02_02',
    'ewc.18_02_03',
    'ewc.18_02_05',
    'ewc.18_02_06',
    'ewc.18_02_07',
    'ewc.18_02_08',
  ],
  'ewc.19_01': [
    'ewc.19_01_02',
    'ewc.19_01_05',
    'ewc.19_01_06',
    'ewc.19_01_07',
    'ewc.19_01_10',
    'ewc.19_01_11',
    'ewc.19_01_12',
    'ewc.19_01_13',
    'ewc.19_01_14',
    'ewc.19_01_15',
    'ewc.19_01_16',
    'ewc.19_01_17',
    'ewc.19_01_18',
    'ewc.19_01_19',
    'ewc.19_01_99',
  ],
  'ewc.19_02': [
    'ewc.19_02_03',
    'ewc.19_02_04',
    'ewc.19_02_05',
    'ewc.19_02_06',
    'ewc.19_02_07',
    'ewc.19_02_08',
    'ewc.19_02_09',
    'ewc.19_02_10',
    'ewc.19_02_11',
    'ewc.19_02_99',
  ],
  'ewc.19_03': ['ewc.19_03_04', 'ewc.19_03_05', 'ewc.19_03_06', 'ewc.19_03_07'],
  'ewc.19_04': ['ewc.19_04_01', 'ewc.19_04_02', 'ewc.19_04_03', 'ewc.19_04_04'],
  'ewc.19_05': ['ewc.19_05_01', 'ewc.19_05_02', 'ewc.19_05_03', 'ewc.19_05_99'],
  'ewc.19_06': [
    'ewc.19_06_03',
    'ewc.19_06_04',
    'ewc.19_06_05',
    'ewc.19_06_06',
    'ewc.19_06_99',
  ],
  'ewc.19_08': [
    'ewc.19_08_01',
    'ewc.19_08_02',
    'ewc.19_08_05',
    'ewc.19_08_06',
    'ewc.19_08_07',
    'ewc.19_08_08',
    'ewc.19_08_09',
    'ewc.19_08_10',
    'ewc.19_08_11',
    'ewc.19_08_12',
    'ewc.19_08_13',
    'ewc.19_08_14',
    'ewc.19_08_99',
  ],
  'ewc.19_09': [
    'ewc.19_09_01',
    'ewc.19_09_02',
    'ewc.19_09_03',
    'ewc.19_09_04',
    'ewc.19_09_05',
    'ewc.19_09_06',
    'ewc.19_09_99',
  ],
  'ewc.19_10': [
    'ewc.19_10_01',
    'ewc.19_10_02',
    'ewc.19_10_03',
    'ewc.19_10_04',
    'ewc.19_10_05',
    'ewc.19_10_06',
  ],
  'ewc.19_11': [
    'ewc.19_11_01',
    'ewc.19_11_02',
    'ewc.19_11_03',
    'ewc.19_11_04',
    'ewc.19_11_05',
    'ewc.19_11_06',
    'ewc.19_11_07',
    'ewc.19_11_99',
  ],
  'ewc.19_12': [
    'ewc.19_12_01',
    'ewc.19_12_02',
    'ewc.19_12_03',
    'ewc.19_12_04',
    'ewc.19_12_05',
    'ewc.19_12_06',
    'ewc.19_12_07',
    'ewc.19_12_08',
    'ewc.19_12_09',
    'ewc.19_12_10',
    'ewc.19_12_11',
    'ewc.19_12_12',
  ],
  'ewc.19_13': [
    'ewc.19_13_01',
    'ewc.19_13_02',
    'ewc.19_13_03',
    'ewc.19_13_04',
    'ewc.19_13_05',
    'ewc.19_13_06',
    'ewc.19_13_07',
    'ewc.19_13_08',
  ],
  'ewc.19_80': ['ewc.19_80_01'],
  'ewc.20_01': [
    'ewc.20_01_01',
    'ewc.20_01_02',
    'ewc.20_01_08',
    'ewc.20_01_10',
    'ewc.20_01_11',
    'ewc.20_01_13',
    'ewc.20_01_14',
    'ewc.20_01_15',
    'ewc.20_01_17',
    'ewc.20_01_19',
    'ewc.20_01_21',
    'ewc.20_01_23',
    'ewc.20_01_25',
    'ewc.20_01_26',
    'ewc.20_01_27',
    'ewc.20_01_28',
    'ewc.20_01_29',
    'ewc.20_01_30',
    'ewc.20_01_31',
    'ewc.20_01_32',
    'ewc.20_01_33',
    'ewc.20_01_34',
    'ewc.20_01_35',
    'ewc.20_01_36',
    'ewc.20_01_37',
    'ewc.20_01_38',
    'ewc.20_01_39',
    'ewc.20_01_40',
    'ewc.20_01_41',
    'ewc.20_01_80',
    'ewc.20_01_99',
  ],
  'ewc.20_02': ['ewc.20_02_01', 'ewc.20_02_02', 'ewc.20_02_03'],
  'ewc.20_03': [
    'ewc.20_03_01',
    'ewc.20_03_02',
    'ewc.20_03_03',
    'ewc.20_03_04',
    'ewc.20_03_06',
    'ewc.20_03_07',
    'ewc.20_03_99',
  ],
};

const mapEwc = () => {
  const groups = [];
  for (const [key, value] of Object.entries(waste_ewc)) {
    const items = value.map((x) => [x, x]);
    groups.push([`dropdowns.${key}`, items]);
  }
  return groups;
};

export const waste_types = [
  ['others', [['other.manual_factor', 'other.manual_factor']]],
  [
    'construction_title',
    [
      ['construction.aggregates', 'construction.aggregates'],
      [
        'construction.average_construction',
        'construction.average_construction',
      ],
      ['construction.asbestos', 'construction.asbestos'],
      ['construction.asphalt', 'construction.asphalt'],
      ['construction.bricks', 'construction.bricks'],
      ['construction.concrete', 'construction.concrete'],
      ['construction.insulation', 'construction.insulation'],
      ['construction.metals', 'construction.metals'],
      ['construction.soils', 'construction.soils'],
      ['construction.mineral_oil', 'construction.mineral_oil'],
      ['construction.plasterboard', 'construction.plasterboard'],
      ['construction.tyres', 'construction.tyres'],
      ['construction.wood', 'construction.wood'],
    ],
  ],
  [
    'refuse_title',
    [
      ['refuse.household_residual_waste', 'refuse.household_residual_waste'],
      [
        'refuse.organic_food_and_drink_waste',
        'refuse.organic_food_and_drink_waste',
      ],
      ['refuse.organic_garden_waste', 'refuse.organic_garden_waste'],
      [
        'refuse.organic_mixed_food_and_garden_waste',
        'refuse.organic_mixed_food_and_garden_waste',
      ],
      [
        'refuse.commercial_and_industrial_waste',
        'refuse.commercial_and_industrial_waste',
      ],
    ],
  ],
  [
    'others',
    [
      ['other.books', 'other.books'],
      ['other.glass', 'other.glass'],
      ['other.clothing', 'other.clothing'],
    ],
  ],
  [
    'electrical_items_title',
    [
      [
        'electrical_items.weee_fridges_and_freezers',
        'electrical_items.weee_fridges_and_freezers',
      ],
      ['electrical_items.weee_large', 'electrical_items.weee_large'],
      ['electrical_items.weee_mixed', 'electrical_items.weee_mixed'],
      ['electrical_items.weee_small', 'electrical_items.weee_small'],
      ['electrical_items.batteries', 'electrical_items.batteries'],
    ],
  ],
  [
    'metal_title',
    [
      [
        'metal.aluminium_cans_and_foil_excl_forming',
        'metal.aluminium_cans_and_foil_excl_forming',
      ],
      ['metal.mixed_cans', 'metal.mixed_cans'],
      ['metal.scrap_metal', 'metal.scrap_metal'],
      ['metal.steel_cans', 'metal.steel_cans'],
    ],
  ],
  [
    'plastic_title',
    [
      ['plastic.average_plastics', 'plastic.average_plastics'],
      ['plastic.average_plastic_film', 'plastic.average_plastic_film'],
      ['plastic.average_plastic_rigid', 'plastic.average_plastic_rigid'],
      ['plastic.hdpe_incl_forming', 'plastic.hdpe_incl_forming'],
      [
        'plastic.ldpe_and_lldpe_incl_forming',
        'plastic.ldpe_and_lldpe_incl_forming',
      ],
      ['plastic.pet_incl_forming', 'plastic.pet_incl_forming'],
      ['plastic.pp_incl_forming', 'plastic.pp_incl_forming'],
      ['plastic.ps_incl_forming', 'plastic.ps_incl_forming'],
      ['plastic.pvc_incl_forming', 'plastic.pvc_incl_forming'],
    ],
  ],
  [
    'paper_title',
    [
      ['paper.paper_and_board_board', 'paper.paper_and_board_board'],
      ['paper.paper_and_board_mixed', 'paper.paper_and_board_mixed'],
      ['paper.paper_and_board_paper', 'paper.paper_and_board_paper'],
    ],
  ],
  [
    'sewage_treatment',
    [
      ['sewage_treatment.sewers_etc', 'sewage_treatment.sewers_etc'],
      ['sewage_treatment.gravity_etc', 'sewage_treatment.gravity_etc'],
      ['sewage_treatment.composting_etc', 'sewage_treatment.composting_etc'],
    ],
  ],
  [
    'sewage_disposal',
    [
      ['sewage_disposal.raw_limed_cake', 'sewage_disposal.raw_limed_cake'],
      ['sewage_disposal.raw_sludge', 'sewage_disposal.raw_sludge'],
      [
        'sewage_disposal.anaerobically_digested_sludge',
        'sewage_disposal.anaerobically_digested_sludge',
      ],
      ['sewage_disposal.incineration', 'sewage_disposal.incineration'],
    ],
  ],
  [
    'hazardous_waste',
    [
      ['hazardous.industrial_landfill', 'hazardous.industrial_landfill'],
      [
        'hazardous.industrial_incineration',
        'hazardous.industrial_incineration',
      ],
    ],
  ],
  ['medical_waste', [['medical.medical_waste', 'medical.medical_waste']]],
  ...mapEwc(),
];

export const product_industry = [
  [
    'agriculture_hunting_forestry_and_fishing',
    'industries.agriculture_hunting_forestry_and_fishing',
  ],
  ['air_transport', 'industries.air_transport'],
  [
    'basic_metals_and_fabricated_metal',
    'industries.basic_metals_and_fabricated_metal',
  ],
  [
    'chemicals_and_chemical_products',
    'industries.chemicals_and_chemical_products',
  ],
  [
    'coke_refined_petroleum_and_nuclear_fuel',
    'industries.coke_refined_petroleum_and_nuclear_fuel',
  ],
  [
    'computers_and_ofice_equipments',
    'industries.computers_and_ofice_equipments',
  ],
  ['construction', 'industries.construction'],
  ['education', 'industries.education'],
  [
    'electrical_and_optical_equipment',
    'industries.electrical_and_optical_equipment',
  ],
  ['financial_intermediation', 'industries.financial_intermediation'],
  ['food_beverages_and_tobacco', 'industries.food_beverages_and_tobacco'],
  ['furniture', 'industries.furniture'],
  ['health_and_social_work', 'industries.health_and_social_work'],
  ['hotels_and_restaurants', 'industries.hotels_and_restaurants'],
  ['inland_transport', 'industries.inland_transport'],
  ['leather_leather_and_footwear', 'industries.leather_leather_and_footwear'],
  [
    'machinery_not_elsewhere_classified',
    'industries.machinery_not_elsewhere_classified',
  ],
  [
    'manufacturing_not_elsewhere_classified_recycling',
    'industries.manufacturing_not_elsewhere_classified_recycling',
  ],
  ['mining_and_quarrying', 'industries.mining_and_quarrying'],
  ['office_consumables', 'industries.office_consumables'],
  ['other', 'industries.other'],
  [
    'other_community_social_and_personal_services',
    'industries.other_community_social_and_personal_services',
  ],
  ['other_non_metallic_mineral', 'industries.other_non_metallic_mineral'],
  [
    'other_supporting_and_auxiliary_transport_activities_activities_of_travel_agencies',
    'industries.other_supporting_and_auxiliary_transport_activities_activities_of_travel_agencies',
  ],
  ['post_and_telecommunications', 'industries.post_and_telecommunications'],
  [
    'private_households_with_employed_persons',
    'industries.private_households_with_employed_persons',
  ],
  [
    'public_admin_and_defense_compulsory_social_securitya',
    'industries.public_admin_and_defense_compulsory_social_securitya',
  ],
  [
    'pulp_paper_paper__printing_and_publishing',
    'industries.pulp_paper_paper__printing_and_publishing',
  ],
  ['real_estate_activities', 'industries.real_estate_activities'],
  [
    'renting_of_m_eq_and_other_business_activities',
    'industries.renting_of_m_eq_and_other_business_activities',
  ],
  [
    'retail_trade_except_of_motor_vehicles_and_motorcycles_repair_of_household_goods',
    'industries.retail_trade_except_of_motor_vehicles_and_motorcycles_repair_of_household_goods',
  ],
  ['rubber_and_plastics', 'industries.rubber_and_plastics'],
  [
    'sale_maintenance_and_repair_of_motor_vehicles_and_motorcycles_retail_sale_of_fuel',
    'industries.sale_maintenance_and_repair_of_motor_vehicles_and_motorcycles_retail_sale_of_fuel',
  ],
  ['textiles_and_textile_products', 'industries.textiles_and_textile_products'],
  ['transport_equipment', 'industries.transport_equipment'],
  ['water_transport', 'industries.water_transport'],
  [
    'wholesale_trade_and_commission_trade_except_of_motor_vehicles_and_motorcycles',
    'industries.wholesale_trade_and_commission_trade_except_of_motor_vehicles_and_motorcycles',
  ],
  [
    'wood_and_products_of_wood_and_cork',
    'industries.wood_and_products_of_wood_and_cork',
  ],
  [
    'services_with_a_low_equipment_level',
    'industries.services_with_a_low_equipment_level',
  ],
  [
    'services_with_high_equipment_level',
    'industries.services_with_high_equipment_level',
  ],
];

// there's no way we're translating this all by hand
export const translateTypeOfTransportation = (key: string) => {
  const baseKey = 'dropdowns.transport';
  const keys = key.split('.');
  return keys.map((key) => `$t(${baseKey}.${key})`).join(' - ');
};

export const transport_units = [
  ['other.manual_factor', 'other.manual_factor'],
  ['zero_emission_vehicle', 'transport.zero_emission_vehicle'],
  ['van', 'transport.van'],
  ['hgv', 'transport.hgv'],
  [
    'freight_flights_short_haul_up_to_4h',
    'transport.freight_flights_short_haul_up_to_4h',
  ],
  [
    'freight_flights_long_haul_more_than_4h',
    'transport.freight_flights_long_haul_more_than_4h',
  ],
  ['freight_train', 'transport.freight_train'],
  ['sea_tanker_crude_tanker', 'transport.sea_tanker_crude_tanker'],
  ['sea_tanker_products_tanker', 'transport.sea_tanker_products_tanker'],
  ['sea_tanker_chemical_tanker', 'transport.sea_tanker_chemical_tanker'],
  ['sea_tanker_lng_tanker', 'transport.sea_tanker_lng_tanker'],
  ['sea_tanker_lpg_tanker', 'transport.sea_tanker_lpg_tanker'],
  ['cargo_ship_bulk_carrier', 'transport.cargo_ship_bulk_carrier'],
  ['cargo_ship_general_cargo', 'transport.cargo_ship_general_cargo'],
  ['cargo_ship_container_ship', 'transport.cargo_ship_container_ship'],
  ['cargo_ship_vehicle_transport', 'transport.cargo_ship_vehicle_transport'],
  ['cargo_ship_roro_ferry', 'transport.cargo_ship_roro_ferry'],
  ['cargo_ship_large_ropax_ferry', 'transport.cargo_ship_large_ropax_ferry'],
];

const transport_units_extras = [
  'hgv.artic_avg.laden_100pct',
  'hgv.artic_avg.laden_50pct',
  'hgv.artic_avg.laden_avg',
  'hgv.artic_over33t.laden_100pct',
  'hgv.artic_over33t.laden_50pct',
  'hgv.artic_over33t.laden_avg',
  'hgv.artic_upto33t.laden_100pct',
  'hgv.artic_upto33t.laden_50pct',
  'hgv.artic_upto33t.laden_avg',
  'hgv.hgv_avg.laden_100pct',
  'hgv.hgv_avg.laden_50pct',
  'hgv.rigid_avg.laden_100pct',
  'hgv.rigid_avg.laden_50pct',
  'hgv.rigid_avg.laden_avg',
  'hgv.rigid_over17t.laden_100pct',
  'hgv.rigid_over17t.laden_50pct',
  'hgv.rigid_over17t.laden_avg',
  'hgv.rigid_upto17t.laden_100pct',
  'hgv.rigid_upto17t.laden_50pct',
  'hgv.rigid_upto17t.laden_avg',
  'hgv.rigid_upto7t.laden_100pct',
  'hgv.rigid_upto7t.laden_50pct',
  'hgv.rigid_upto7t.laden_avg',
  'hgv_refrigerated.artic_avg.laden_100pct',
  'hgv_refrigerated.artic_avg.laden_50pct',
  'hgv_refrigerated.artic_avg.laden_avg',
  'hgv_refrigerated.artic_over33t.laden_100pct',
  'hgv_refrigerated.artic_over33t.laden_50pct',
  'hgv_refrigerated.artic_over33t.laden_avg',
  'hgv_refrigerated.artic_upto33t.laden_100pct',
  'hgv_refrigerated.artic_upto33t.laden_50pct',
  'hgv_refrigerated.artic_upto33t.laden_avg',
  'hgv_refrigerated.hgv_avg.laden_100pct',
  'hgv_refrigerated.hgv_avg.laden_50pct',
  'hgv_refrigerated.hgv_avg.laden_avg',
  'hgv_refrigerated.rigid_avg.laden_100pct',
  'hgv_refrigerated.rigid_avg.laden_50pct',
  'hgv_refrigerated.rigid_avg.laden_avg',
  'hgv_refrigerated.rigid_over17t.laden_100pct',
  'hgv_refrigerated.rigid_over17t.laden_50pct',
  'hgv_refrigerated.rigid_over17t.laden_avg',
  'hgv_refrigerated.rigid_upto17t.laden_100pct',
  'hgv_refrigerated.rigid_upto17t.laden_50pct',
  'hgv_refrigerated.rigid_upto17t.laden_avg',
  'hgv_refrigerated.rigid_upto7t.laden_100pct',
  'hgv_refrigerated.rigid_upto7t.laden_50pct',
  'hgv_refrigerated.rigid_upto7t.laden_avg',
  'van.class_1.diesel',
  'van.class_1.ev_battery',
  'van.class_1.petrol',
  'van.class_2.diesel',
  'van.class_2.ev_battery',
  'van.class_2.petrol',
  'van.class_3.diesel',
  'van.class_3.ev_battery',
  'van.class_3.petrol',
  'van.class_avg.cng',
  'van.class_avg.diesel',
  'van.class_avg.ev_battery',
  'van.class_avg.lpg',
  'van.class_avg.petrol',
];

transport_units.push(
  ...transport_units_extras.map((key) => [
    key,
    translateTypeOfTransportation(key),
  ])
);

export const unit_of_weight = [
  ['kg', 'kg'],
  ['tons', 'tons'],
];

export const type_of_vehicle = [
  ['other.manual_factor', 'other.manual_factor'],
  ['car_small', 'car_small'],
  ['car_medium', 'car_medium'],
  ['car_large', 'car_large'],
  ['car_average', 'car_average'],
  ['motorbike', 'motorbike'],
  ['van', 'van'],
];

export const type_of_fuel = [
  ['diesel', 'diesel'],
  ['petrol', 'petrol'],
  ['hybrid', 'hybrid'],
  ['cng', 'cng'],
  ['lpg', 'lpg'],
  ['unknown', 'unknown'],
  ['plug_in_hybrid_vehicle', 'plug_in_hybrid_vehicle'],
  ['other.manual_factor', 'other.manual_factor'],
];

export const unit_of_distance = [
  ['km', 'km'],
  ['miles', 'miles'],
];

export const vehicle_names_en = [
  ['Passenger Car', 'Passenger Car'],
  [
    'Light-duty Trucks (Vans, Pickup Trucks, SUVs)',
    'Light-duty Trucks (Vans, Pickup Trucks, SUVs)',
  ],
  ['Medium- and Heavy-duty Vehicles', 'Medium- and Heavy-duty Vehicles'],
  ['Bus', 'Bus'],
  ['Agricultural Equipment', 'Agricultural Equipment'],
  ['Ships and Boats', 'Ships and Boats'],
  ['Motorcycles', 'Motorcycles'],
  ['Other Non-Road Vehicles', 'Other Non-Road Vehicles'],
  ['Locomotives', 'Locomotives'],
  ['Construction/Mining Equipment', 'Construction/Mining Equipment'],
  ['Industrial/Commercial Equipment', 'Industrial/Commercial Equipment'],
  ['Lawn and Garden Equipment', 'Lawn and Garden Equipment'],
  ['Logging Equipment', 'Logging Equipment'],
  ['Railroad Equipment', 'Railroad Equipment'],
  ['Recreational Equipment', 'Recreational Equipment'],
  ['Electric Vehicle', 'Electric Vehicle'],
  ['other', 'other'],
];

export const vehicle_names_pl = [
  ['Samochód osobowy', 'Samochód osobowy'],
  [
    'Lekkie samochody ciężarowe (furgonetki, pickupy, SUV-y)',
    'Lekkie samochody ciężarowe (furgonetki, pickupy, SUV-y)',
  ],
  ['Pojazdy średnie i ciężkie', 'Pojazdy średnie i ciężkie'],
  ['Bus', 'Bus'],
  ['Sprzęt rolniczy', 'Sprzęt rolniczy'],
  ['Statki i łodzie', 'Statki i łodzie'],
  ['Motocykle', 'Motocykle'],
  [
    'Inne pojazdy nieporuszające się po drogach',
    'Inne pojazdy nieporuszające się po drogach',
  ],
  ['Lokomotywy', 'Lokomotywy'],
  ['Sprzęt budowlany/ górniczy', 'Sprzęt budowlany/ górniczy'],
  ['Urządzenia przemysłowe/komercyjne', 'Urządzenia przemysłowe/komercyjne'],
  [
    'Sprzęt do pielęgnacji trawników i ogrodów',
    'Sprzęt do pielęgnacji trawników i ogrodów',
  ],
  ['Sprzęt do pozyskiwania drewna', 'Sprzęt do pozyskiwania drewna'],
  ['Urządzenia kolejowe', 'Urządzenia kolejowe'],
  ['Sprzęt rekreacyjny', 'Sprzęt rekreacyjny'],
  ['Pojazd elektryczny', 'Pojazd elektryczny'],
  ['inne', 'inne'],
];

export const average_method_goods = [
  'material.construction.aggregates',
  'material.construction.average_construction',
  'material.construction.asbestos',
  'material.construction.asphalt',
  'material.construction.bricks',
  'material.construction.concrete',
  'material.construction.insulation',
  'material.construction.metals',
  'material.construction.soils',
  'material.construction.mineral_oil',
  'material.construction.plasterboard',
  'material.construction.tyres',
  'material.construction.wood',

  //'material.other.books',
  'material.other.glass',
  'material.other.clothing',
  'material.other.food_and_drink',

  'material.organic.compost_derived_from_garden_waste',
  'material.organic.compost_derived_from_food_and_garden_waste',

  'material.electrical_items.fridges_and_freezers',
  'material.electrical_items.large',
  'material.electrical_items.small',
  'material.electrical_items.it',
  'material.electrical_items.batteries_alkaline',
  'material.electrical_items.batteries_li_ion',
  'material.electrical_items.batteries_nimh',

  'material.metal.aluminium_cans_and_foil_excl_forming',
  'material.metal.mixed_cans',
  'material.metal.scrap_metal',
  'material.metal.steel_cans',

  'material.plastic.average_plastics',
  'material.plastic.average_plastic_film',
  'material.plastic.average_plastic_rigid',
  'material.plastic.hdpe_incl_forming',
  'material.plastic.ldpe_and_lldpe_incl_forming',
  'material.plastic.pet_incl_forming',
  'material.plastic.pp_incl_forming',
  'material.plastic.ps_incl_forming',
  'material.plastic.pvc_incl_forming',

  'material.paper.paper_and_board_board',
  'material.paper.paper_and_board_mixed',
  'material.paper.paper_and_board_paper',
  'agriculture.mineral_fertilizers.nitrogen',
  'agriculture.mineral_fertilizers.phosphorus',
  'agriculture.mineral_fertilizers.potassium',
  'agriculture.mineral_fertilizers.calcium',
  'agriculture.plant_protection_products',
  'agriculture.natural_fertilizers',
  'agriculture.crop_residues.burning_from_n2o',
  'agriculture.crop_residues.burning_from_ch4',
  'agriculture.crop_residues_from_n2o',
  'agriculture.traditional_cultivation',
  'agriculture.simplified_cultivation',
  'agriculture.direct_sowing',
  'agriculture.winter_wheat',
  'agriculture.winter_triticale',
  'agriculture.spring_barley',
  'agriculture.cereal_mix',
  'agriculture.corn_for_grain',
  'agriculture.corn_for_silage',
  'agriculture.potato',
  'agriculture.sugar_beet',
  'agriculture.winter_rapeseed',
].map((x) => [x, x]);
