import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { useLanguage } from 'src/common/hooks';
import colors from 'src/theme/colors';

export default function Head() {
  const { t } = useLanguage();

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          rowSpan={2}
          colSpan={1}
        >
          {t('esg.dma.tableV2.columns.type')}
        </TableCell>
        <TableCell rowSpan={2}>
          {t('esg.dma.tableV2.columns.description')}
        </TableCell>
        <TableCell rowSpan={2}>
          {t('esg.dma.tableV2.columns.category')}
        </TableCell>
        <TableCell rowSpan={2}>
          {t('esg.dma.tableV2.columns.humanRights')}
        </TableCell>
        <TableCell
          colSpan={3}
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('esg.dma.tableV2.columns.affectedStage')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('esg.dma.tableV2.columns.upstream')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundGreyDark }}
          align="center"
        >
          {t('esg.dma.tableV2.columns.OwnOperations')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('esg.dma.tableV2.columns.downstream')}
        </TableCell>
        <TableCell>&nbsp;</TableCell>
        <TableCell>&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}
