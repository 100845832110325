import React, { useContext, useRef, useState } from 'react';
import useFileUpload from 'react-use-file-upload';
import cloud from '../../../../../../images/cloud.png';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { client } from '../../../../../../utils/api-client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Button,
  StyledDialog,
  StyledDialogContent,
  CloseIcon as StyledCloseIcon,
  FileWrapper,
  UploadedTitle,
} from './index.styles';
import { SuccessIcon } from './icon';
import CloseIcon from '../../../../../Pages/ESG/create/closeIcon';
import { FieldValue } from '../types';
import QuestionnaireContext from '../../../Data/QuestionnaireContext';

interface Props {
  url: string;
  open: boolean;
  onClose: Function;
  handleChange: Function;
  type: string;
  value: FieldValue;
  setError: Function;
}

const fileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',

  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',

  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',

  'text/plain',
  'application/zip',
];

export default function Modal({
  url,
  open,
  onClose,
  handleChange,
  type,
  // @ts-ignore
  value = [],
  setError,
}: Props) {
  const {
    files,
    fileNames,
    handleDragDropEvent,
    setFiles,
    removeFile,
    clearAllFiles,
  } = useFileUpload();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { tokenAuth } = useContext(QuestionnaireContext);

  const [uploadSuccess, setUploadSuccess] = useState(false);

  React.useEffect(() => clearAllFiles(), [open]);

  const upload = useMutation({
    mutationFn: () => {
      const formData = new FormData();
      files.map((file) => formData.append(`file`, file, file.name));
      return tokenAuth().then((token) => {
        return client.post(url, formData, {
          headers: {
            'X-Questionnaires-Token': token,
          },
        });
      });
    },
    onSuccess: ({ data }) => {
      // @ts-ignore
      handleChange(type === 'simple-file-picker' ? data : [...value, data]);
      setUploadSuccess(true);
      setError(undefined);
    },
    onError: () => {
      // TODO: ogarnać errory
      setError('Error');
    },
  });

  const handleClose = () => {
    setError('');
    clearAllFiles();
    setUploadSuccess(false);
    onClose();
  };

  return (
    <StyledDialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <div className="wrapper">
        <span>{t('esg.import')}</span>
        <StyledCloseIcon onClick={handleClose}>
          <CloseIcon />
        </StyledCloseIcon>
      </div>
      <StyledDialogContent>
        {!fileNames.length && (
          <div
            onDragEnter={(e: any) => handleDragDropEvent(e)}
            onDragOver={(e: any) => handleDragDropEvent(e)}
            onDrop={(e: any) => {
              if (fileTypes.includes(e?.dataTransfer.files[0].type)) {
                handleDragDropEvent(e);
                setFiles(e);
              } else {
                setError('Error type');
              }
            }}
          >
            <div className="content-wrapper">
              <img src={cloud} alt="cloud excel upload" />
              <button type="button" onClick={() => inputRef.current?.click()}>
                <span>{t('popup.excel.search')}</span>
              </button>
              <div>{t('popup.excel.drop')}</div>
            </div>
          </div>
        )}

        {!!fileNames.length && (
          <>
            {uploadSuccess && (
              <div>
                <SuccessIcon />
                <UploadedTitle>{t('popup.excel.success')}</UploadedTitle>
              </div>
            )}

            {!uploadSuccess && (
              <div>
                {fileNames.map((name) => (
                  <FileWrapper key={name} className="file-wrapper">
                    <span>{name}</span>
                    <span
                      onClick={() => {
                        removeFile(name);
                        setError('');
                      }}
                    >
                      {t('popup.excel.remove')}
                    </span>
                  </FileWrapper>
                ))}
                <Button
                  disabled={!files.length}
                  onClick={() => upload.mutate()}
                  type="button"
                >
                  {t('esg.upload')}
                </Button>
              </div>
            )}
          </>
        )}
      </StyledDialogContent>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={(e: any) => {
          setFiles(e);
        }}
        multiple={false}
        type="file"
        accept={fileTypes.join(', ')}
      />
    </StyledDialog>
  );
}
