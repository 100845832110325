import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';

export default function Head() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.dma.step5TableV2.head',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          rowSpan={2}
          colSpan={1}
        >
          {t('perspective')}
        </TableCell>
        <TableCell colSpan={8} align="center">
          {t('impactEvaluation')}
        </TableCell>
        <TableCell colSpan={4} align="center">
          {t('financialEvaluation')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>{t('scale')}</TableCell>
        <TableCell>{t('scope')}</TableCell>
        <TableCell>{t('probability')}</TableCell>
        <TableCell>{t('irreversible')}</TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('impactResult')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('stakeholdersResult')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('finalResult')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('isImpactMaterial')}
        </TableCell>
        <TableCell>{t('financialScale')}</TableCell>
        <TableCell>{t('probability')}</TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('financialResult')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('isRiskOpportunityMaterial')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
