import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContactUsPrompt, useGhgOrganization } from 'src/common/hooks';
import { useGhgFactorEntries } from 'src/Ghg/hooks';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from 'src/Ghg/interfaces';
import { useGoodsAndServices } from '../../hooks';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { EcoinventMethodGoodData, GoodsAndServicesData } from '../../types';
import { toast } from 'react-toastify';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import {
  AddCircle,
  ContentCopy,
  DeleteOutline,
  Edit,
} from '@mui/icons-material';
import { Box } from '@mui/system';
import FormModal from './FormModal';
import MethodDescriptionPanel from '../../components/MethodDescriptionPanel';
import {
  getColorFromNumber,
  getPeriodDate,
  questionForGoodType,
} from 'src/Ghg/utils';

interface Props {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}

function modifyArray(
  array: EcoinventMethodGoodData[],
  index: number | null,
  value: EcoinventMethodGoodData | null
) {
  if (value === null) {
    return array.filter((_, i) => i !== index);
  } else if (index === null) {
    return [...array, value];
  } else {
    return array.map((item, i) => (i === index ? value : item));
  }
}

function formatShare(value: number | null) {
  return value === null ? '' : (100 * value).toFixed(2);
}

export default function EcoinventMethodBox({ type }: Props) {
  const organization = useGhgOrganization();
  const period = getPeriodDate(organization.co2.reporting_freq);
  const { navigateMailTo } = useContactUsPrompt();
  const { t } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}`,
  });
  const { t: methodTranslation } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}.method3`,
  });

  const factors = useGhgFactorEntries();

  const data = useGoodsAndServices({
    organization_id: organization.id,
    period,
    type,
  });

  const [editedEntryIndex, setEditedEntryIndex] = useState<number | null>(null);
  const [formActive, setFormActive] = useState<boolean>(false);

  const openModal = (editIndex?: number) => {
    setEditedEntryIndex(editIndex === undefined ? null : editIndex);
    setFormActive(true);
  };

  const handleFormSave = (
    index: number | null,
    entry: EcoinventMethodGoodData | null
  ) => {
    if (index === null && entry === null) {
      // this should not happen
      toast.error('Error occured while saving data');
      return;
    }
    const oldData = data.query.data!;
    const newData: GoodsAndServicesData = {
      data: oldData.data,
      average_method_data: oldData.average_method_data,
      ecoinvent_method_data: modifyArray(
        oldData.ecoinvent_method_data,
        index,
        entry
      ),
    };
    data.update.mutate(newData, {
      onSuccess: () => {
        setFormActive(false);
      },
      onError(error: any) {
        toast.error(error?.message || 'Error');
      },
    });
  };

  if (data.isLoading || factors.isLoading) return <PageLoading />;
  if (data.isError || factors.isError) return <SomethingWentWrong />;

  if (factors.data.length === 0) {
    return (
      <MethodDescriptionPanel
        t={methodTranslation}
        buttonTextKey="buttonAskForAccess"
        onButtonClick={navigateMailTo}
      />
    );
  }

  const methodData = data.query.data!.ecoinvent_method_data;
  const emissionShares = data.queryEmissions.data!.ecoinvent_method_data;

  if (methodData.length === 0) {
    return (
      <>
        <FormModal
          type={type}
          index={editedEntryIndex}
          open={formActive}
          onClose={() => setFormActive(false)}
          onSave={handleFormSave}
        />
        <MethodDescriptionPanel
          t={methodTranslation}
          buttonTextKey="buttonAddData"
          onButtonClick={openModal}
        />
      </>
    );
  }

  return (
    <>
      <FormModal
        type={type}
        index={editedEntryIndex}
        open={formActive}
        onClose={() => setFormActive(false)}
        onSave={handleFormSave}
      />
      <Typography variant="h3" sx={{ mb: '24px' }}>
        {t('method3.title')}
      </Typography>
      <StyledPanel>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="33%" sx={{ ml: '6px' }}>
                  {t('table.column1')}
                </TableCell>
                <TableCell width="33%" sx={{ ml: '6px' }}>
                  {t('table.column4')}
                </TableCell>
                <TableCell width="33%" sx={{ ml: '6px' }}>
                  {t('table.column3')}
                </TableCell>
                <TableCell sx={{ ml: '6px' }}>{t('table.edit')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {methodData.map((elem, id) => (
                <TableRow key={id}>
                  <TableCell
                    sx={{ borderLeft: `6px solid ${getColorFromNumber(id)}` }}
                  >
                    {elem.name_label}
                  </TableCell>
                  <TableCell
                    sx={{ borderLeft: `6px solid ${getColorFromNumber(id)}` }}
                  >
                    {t('table.company')}
                  </TableCell>
                  <TableCell
                    sx={{ borderLeft: `6px solid ${getColorFromNumber(id)}` }}
                  >
                    {formatShare(emissionShares[id]?.share)}
                  </TableCell>
                  <TableCell
                    sx={{ borderLeft: `6px solid ${getColorFromNumber(id)}` }}
                  >
                    <Box display="flex" flexDirection="row">
                      <IconButton onClick={() => openModal(id)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleFormSave(null, elem)}>
                        <ContentCopy />
                      </IconButton>
                      <IconButton onClick={() => handleFormSave(id, null)}>
                        <DeleteOutline />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPanel>
      <Button
        sx={{ mt: '24px', mb: '24px' }}
        variant="text"
        startIcon={<AddCircle />}
        onClick={() => openModal()}
      >
        <Typography variant="subtitle1">
          {t('method3.buttonAddAnother')}
        </Typography>
      </Button>
    </>
  );
}
