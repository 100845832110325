import React from 'react';
import { TableRow } from '@mui/material';

import {
  EsgDmaImpactOutSchema,
  EsgDmaRiskOpportunityOutSchema,
  TimeHorizon,
} from '../../../types';
import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import TimeHorizonRow from './TimeHorizonRow';

interface Props {
  title: string;
  impact: EsgDmaImpactOutSchema | null;
  riskOpportunity: EsgDmaRiskOpportunityOutSchema | null;
}

export default function MatterRow(props: Props) {
  const { title, impact, riskOpportunity } = props;
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const impactName = impact?.name || '';
  const riskOpportunityName = riskOpportunity?.name || '';
  const separator = impactName && riskOpportunityName ? ' - ' : '';
  const subname = `${impactName}${separator}${riskOpportunityName}`;

  return (
    <>
      <TableRow>
        <CollapsableButtonCell
          style={{ padding: '8px' }}
          colSpan={13}
          text={`${title} > ${subname}`}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </TableRow>

      {!collapsed &&
        Object.values(TimeHorizon).map((timeHorizon) => (
          <TimeHorizonRow
            key={`${timeHorizon}-${impact?.id}-${riskOpportunity?.id}`}
            impact={impact}
            riskOpportunity={riskOpportunity}
            timeHorizon={timeHorizon}
          />
        ))}
    </>
  );
}
