import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { useLanguage } from 'src/common/hooks';
import colors from 'src/theme/colors';

export default function Head() {
  const { t } = useLanguage({
    keyPrefix: 'esg.dma.financialTopicsEvaluationTableV2.columns',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          rowSpan={2}
          colSpan={1}
        >
          {t('riskOrOpportunityName')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          rowSpan={2}
        >
          {t('associatedImpactName')}
        </TableCell>
        <TableCell rowSpan={2}>{t('riskOrOpportunityDescription')}</TableCell>
        <TableCell rowSpan={2}>{t('riskOrOpportunityType')}</TableCell>
        <TableCell
          colSpan={3}
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('valueChainStage')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('upstream')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundGreyDark }}
          align="center"
        >
          {t('ownOperations')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('downstream')}
        </TableCell>
        <TableCell>&nbsp;</TableCell>
        <TableCell>&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}
