import React, { useContext, useEffect, useState } from 'react';
import { UniversalField } from '../types';
import QuestionnaireContext from '../../../Data/QuestionnaireContext';
import styled from 'styled-components';
import Files from './Files';
import RemoveModal from './RemoveModal';
import UploadModal from './UploadModal';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledErrorMessage, StyledTitle } from '../../index.styles';

const FilesWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export default function FileUploadWrapper({
  onChange,
  onForceQuestionAnswerSave,
  value,
  field,
  errors,
}: UniversalField) {
  const { questionnaireAnswerId } = useContext(QuestionnaireContext);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [removeFile, setRemoveFile] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const [uploadOpen, setUploadOpen] = useState(false);

  const triggerQuestionAnswerSave = onForceQuestionAnswerSave;

  const onUploadClose = () => {
    if (field.visual_type === 'simple-file-picker')
      if (!!value) triggerQuestionAnswerSave();

    if (field.visual_type === 'multi-file-picker')
      if (Array.isArray(value) && !!value.length) triggerQuestionAnswerSave();

    setUploadOpen(false);
  };

  /* backend side validation */
  useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  const url = `api-questionnaires/questionnaire-attachments?questionnaire_answer_id=${questionnaireAnswerId}`;

  const handleRemove = (id: string) => {
    if (field.visual_type === 'simple-file-picker') {
      onChange(null);
    }

    if (field.visual_type === 'multi-file-picker') {
      // @ts-ignore
      const newValue = [...value].filter((el) => el.id !== id);
      onChange(newValue);
    }
  };

  const handleCloseRemoveModal = () => setRemoveFile(undefined);

  return (
    <div style={{ marginBottom: '20px' }}>
      {!!field.title && <StyledTitle>{field.title}</StyledTitle>}
      <FilesWrapper>
        <Files value={value} setSelectedFile={setRemoveFile} />
      </FilesWrapper>

      <Button
        className="attache"
        onClick={() => setUploadOpen(true)}
        type="button"
      >
        {t('esg.attach')}
      </Button>

      <RemoveModal
        mutation={() => handleRemove(removeFile as string)}
        open={!!removeFile}
        onClose={handleCloseRemoveModal}
      />
      <UploadModal
        setError={setErrorMessage}
        value={value}
        type={field.visual_type}
        url={url}
        open={uploadOpen}
        onClose={onUploadClose}
        handleChange={onChange}
      />
      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
    </div>
  );
}
