import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '../ReportsIndex/index.styles';
import { useEsgOrganization, useLanguage } from 'src/common/hooks';
import QuestionnaireV3 from '../../../components/QuestionnaireV3';
import { useEsgQuestionnaireAnswerId } from './Hooks/useQuestionnaireAnswerId';
import { createEsgTokenFetcher } from './Hooks/tokenFetcher';
import { ReferecesContext, IReferencesContext } from './references';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';

export default function ESGForm() {
  const params = useParams<{
    formKey: string;
    reportId: string;
  }>();

  const organization = useEsgOrganization();
  const reportId = String(params.reportId);
  const questionnaireKey = String(params.formKey);

  const { data: { questionnaireAnswerId, questionnaireId } = {} } =
    useEsgQuestionnaireAnswerId({
      organizationId: organization.id,
      reportId,
      questionnaireKey,
    });

  const tokenFetcher = useMemo(
    () =>
      createEsgTokenFetcher(organization.id, reportId, questionnaireAnswerId),
    [organization.id, reportId, questionnaireAnswerId]
  );

  const lang = useLanguage();
  const { data: _references } = useQuery(
    ['references', questionnaireKey, lang],
    () =>
      client
        .get<IReferencesContext>(
          `/web/esg/v3/esg-questionnaires/${questionnaireId}/esrs-references`
        )
        .then((res) => res.data),
    {
      enabled: !!questionnaireId,
    }
  );

  const references = useMemo(
    () => _references || { questions: {}, fields: {} },
    [_references]
  );

  return (
    <Container>
      <ReferecesContext.Provider value={references}>
        <QuestionnaireV3
          key={questionnaireAnswerId}
          questionnaireAnswerId={questionnaireAnswerId}
          tokenAuth={tokenFetcher}
          withBulkSaveButtons
        />
      </ReferecesContext.Provider>
    </Container>
  );
}
