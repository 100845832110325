import React from 'react';
import DmaStage from '../components/DmaStage';
import DmaStep from '../components/DmaStep';
import { useLanguage } from 'src/common/hooks';
import { ImageSlider } from '../components/ImageSlider';
import { generateImageUrls } from '../utils';
import LightTooltip from 'src/components/LightTooltip';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { FactCheck } from '@mui/icons-material';

export default function Stage1() {
  const { t, language: l } = useLanguage({ keyPrefix: 'esg.dma' });
  return (
    <DmaStage title={t('stages.informationOverview.title')} color="#A1DF76">
      <DmaStep
        title={t('stages.informationOverview.steps.1')}
        videoComponent={<ImageSlider images={generateImageUrls(l, 3, 4)} />}
      />
      <DmaStep
        title={t('stages.preliminaryAssessmentOfImpactSignificance.steps.3')}
        navigateToComponent={
          <LightTooltip title={t('step.step2TopicEvaluationButton.toggle')}>
            <Link to="./step-1">
              <IconButton>
                <FactCheck />
              </IconButton>
            </Link>
          </LightTooltip>
        }
      />
    </DmaStage>
  );
}
