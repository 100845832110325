import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Checkbox, IconButton, TableCell, TableRow } from '@mui/material';

import {
  EsgDmaRiskOpportunityCategory,
  EsgDmaRiskOpportunityOutSchema,
} from '../../../types';
import CustomSelect from 'src/components/CustomSelect';
import { useDmaImpacts, useRiskOpportunities } from '../../../hooks';
import RemoveIcon from 'src/images/svg/removeIcon';
import ConfirmationModal from 'src/components/ConfirmationModal';
import EditModal from 'src/Esg/Dma/Stakeholders/SelectingStakeholders/SelectingStakeholdersTable/EditModal';
import { useLanguage } from 'src/common/hooks';
import colors from 'src/theme/colors';
import AcceptableTextField from 'src/components/AcceptableTextField';
import DescritionMark from 'src/Esg/Dmav2/components/DescriptionMark';
import { useEsgReports } from 'src/Esg/hooks';
import { parseImpactOptions } from './utils';

interface Props {
  riskOpportunity: EsgDmaRiskOpportunityOutSchema;
  topicColor?: string;
}

export default function RiskOpportunityRow(props: Props) {
  const { riskOpportunity, topicColor } = props;
  const { t } = useLanguage();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { update, _delete } = useRiskOpportunities(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );
  const { _data: impacts } = useDmaImpacts(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);

  const categoryOptions = Object.entries(EsgDmaRiskOpportunityCategory).map(
    ([key, value]) => ({
      value,
      label: t(`esg.dma.step3.tableV2.category.${key}`),
    })
  );

  const impactOptions = parseImpactOptions(riskOpportunity, impacts);

  return (
    <TableRow sx={{ height: '70px' }}>
      <TableCell
        sx={{
          pl: '64px',
          backgroundColor: colors.backgroundInput,
          minWidth: '300px',
        }}
      >
        <AcceptableTextField
          size="small"
          value={riskOpportunity.name}
          onAccept={(value) =>
            update.mutate({ ...riskOpportunity, name: String(value) })
          }
        />
      </TableCell>
      <TableCell
        sx={{
          pl: '64px',
          backgroundColor: colors.backgroundInput,
        }}
      >
        <CustomSelect
          options={impactOptions}
          value={riskOpportunity.esg_dma_impact_id ?? ''}
          onChange={(value) =>
            update.mutate({
              ...riskOpportunity,
              esg_dma_impact_id: value || null,
            })
          }
        />
      </TableCell>
      <TableCell align="center">
        <Box position="relative" display="inline-flex" alignItems="center">
          <EditModal
            title={t(
              'esg.dma.financialTopicsEvaluationTableV2.columns.riskOrOpportunityDescription'
            )}
            value={riskOpportunity.description}
            defaultValue=""
            onChange={(value) =>
              update.mutate({ ...riskOpportunity, description: value })
            }
          />
          <DescritionMark hasDescription={!!riskOpportunity.description} />
        </Box>
      </TableCell>
      <TableCell>
        <CustomSelect
          options={categoryOptions}
          value={riskOpportunity.category}
          onChange={(value) =>
            update.mutate({ ...riskOpportunity, category: value })
          }
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{ backgroundColor: colors.backgroundInput }}
      >
        <Checkbox
          checked={riskOpportunity.upstream_related}
          onChange={(e) =>
            update.mutate({
              ...riskOpportunity,
              upstream_related: e.target.checked,
            })
          }
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{ backgroundColor: colors.backgroundGreyDark }}
      >
        <Checkbox
          checked={riskOpportunity.own_operations_related}
          onChange={(e) =>
            update.mutate({
              ...riskOpportunity,
              own_operations_related: e.target.checked,
            })
          }
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{ backgroundColor: colors.backgroundInput }}
      >
        <Checkbox
          checked={riskOpportunity.downstream_related}
          onChange={(e) =>
            update.mutate({
              ...riskOpportunity,
              downstream_related: e.target.checked,
            })
          }
        />
      </TableCell>

      <TableCell>
        <IconButton onClick={() => setDeleteConfirmationOpen(true)}>
          <RemoveIcon />
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={() => _delete.mutate(riskOpportunity.id)}
        titleKey={t('esg.dma.step3.tableV2.removeRiskOpportunity')}
      />
    </TableRow>
  );
}
