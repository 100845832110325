import React from 'react';
import { TableCell } from '@mui/material';

import { useTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomSelect';
import {
  EsgDmaImpactMatterDataOutSchema,
  EsgDmaImpactOutSchema,
  TimeHorizon,
} from '../../../../types';
import {
  irreversibleOptions,
  probabilityOptions,
  scopeOptions,
  scaleOptions,
} from '../utils';
import { EsgDmaImpactCategory } from 'src/Esg/Dmav2/types';
import { useParams } from 'react-router-dom';
import ImpactScore from '../Scores/ImpactScore';
import { useEsgReports } from 'src/Esg/hooks';
import { useImpactMatterData } from 'src/Esg/Dmav2/hooks';
import { useQueryClient } from 'react-query';

interface Props {
  impact: EsgDmaImpactOutSchema | null;
  timeHorizon: TimeHorizon;
}

export default function ImpactCells(props: Props) {
  const { timeHorizon, impact } = props;
  const queryClient = useQueryClient();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.dma.step5TableV2.body',
  });
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const mattersData = useImpactMatterData(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  if (!impact) return <TableCell colSpan={8} />;
  if (mattersData.isLoading) return null;

  const matterData: Partial<EsgDmaImpactMatterDataOutSchema> =
    mattersData._data.find(
      (i) =>
        i.esg_dma_impact_id === impact?.id && i.time_horizon === timeHorizon
    ) || {
      esg_dma_id: report?.esg_dma_id,
      esg_dma_impact_id: impact?.id,
      time_horizon: timeHorizon,
      impact_scale: null,
      impact_scope: null,
      impact_probability: null,
      impact_irreversibility: null,
    };

  const save = !!matterData?.id ? mattersData.update : mattersData.create;
  const handleChange = (key: string, value: any) =>
    save.mutateAsync({ ...matterData, [key]: value }).then(() => {
      // invalidating impacts to get fresh scores
      queryClient.invalidateQueries(['dma-impacts']);
    });

  const isImpactIrreversibleDisabled =
    !impact ||
    impact.category === EsgDmaImpactCategory.NEGATIVE_REAL ||
    impact.category === EsgDmaImpactCategory.NEGATIVE_POTENTIAL;

  return (
    <>
      <TableCell>
        <CustomSelect
          options={scaleOptions(t)}
          value={matterData.impact_scale}
          onChange={(value) => handleChange('impact_scale', value)}
        />
      </TableCell>
      <TableCell>
        <CustomSelect
          options={scopeOptions(t)}
          value={matterData.impact_scope}
          onChange={(value) => handleChange('impact_scope', value)}
        />
      </TableCell>
      <TableCell>
        <CustomSelect
          options={probabilityOptions(t)}
          value={matterData.impact_probability}
          onChange={(value) => handleChange('impact_probability', value)}
        />
      </TableCell>
      <TableCell>
        <CustomSelect
          options={irreversibleOptions(t)}
          value={matterData.impact_irreversibility}
          onChange={(value) => handleChange('impact_irreversibility', value)}
          disabled={isImpactIrreversibleDisabled}
        />
      </TableCell>

      {timeHorizon === TimeHorizon.SHORT && <ImpactScore impact={impact} />}
    </>
  );
}
