import React from 'react';
import { useTranslation } from 'react-i18next';

interface Point {
  x: number;
  y: number;
}
interface Trace {
  color: string;
  label?: string;
  path: Point[];
}
export interface Props {
  traces: Trace[];
  showDirections: boolean;
}

export const SCALING_FACTOR = 3;

function renderArrow(
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  arrowHeadSize: number,
  props: { stroke: string; strokeWidth: number; opacity?: number }
) {
  const dx = x1 - x0;
  const dy = y1 - y0;
  const sin = dy / Math.sqrt(dx * dx + dy * dy);
  const cos = dx / Math.sqrt(dx * dx + dy * dy);

  const arrowHeadAngle = 40;
  const { stroke, strokeWidth, opacity } = props;

  return (
    <g>
      <polyline points={`${x0},${y0} ${x1},${y1}`} {...props} />
      <polyline
        points={`${x1},${y1} ${x1 - arrowHeadSize * cos},${
          y1 - arrowHeadSize * sin
        }`}
        transform={`rotate(${arrowHeadAngle},${x1},${y1})`}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
      <polyline
        points={`${x1},${y1} ${x1 - arrowHeadSize * cos},${
          y1 - arrowHeadSize * sin
        }`}
        transform={`rotate(${-arrowHeadAngle},${x1},${y1})`}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
    </g>
  );
}

const renderGrid = () => {
  const numGridLines = 6; // Total number of grid lines
  const gridElements: React.ReactNode[] = [];

  // Generate vertical grid lines and labels
  for (let i = 0; i <= numGridLines; i += 1) {
    const position = i / numGridLines;

    // labels
    if (i === 0) {
      gridElements.push(
        <text
          key={`v-text-${i}`}
          x={-0.015}
          y={1.03}
          fontSize="0.02"
          textAnchor="end"
          fill="grey"
        >
          0
        </text>
      );
    } else {
      // vertical
      const label = (position * SCALING_FACTOR).toFixed(1);
      gridElements.push(
        <text
          key={`v-text-${i}`}
          x={position}
          y={1.03}
          fontSize="0.02"
          textAnchor="middle"
          fill="grey"
        >
          {label}
        </text>
      );
      // horizontal
      gridElements.push(
        <text
          key={`h-text-${i}`}
          x={-0.015}
          y={1 - position}
          fontSize="0.02"
          textAnchor="end"
          fill="grey"
          dominantBaseline="middle"
        >
          {label}
        </text>
      );
    }

    // grid lines
    if (i > 0) {
      gridElements.push(
        <line
          key={`v-${i}`}
          x1={position}
          y1={0}
          x2={position}
          y2={1}
          stroke="#B0B0B0"
          strokeWidth={0.002}
        />
      );
      gridElements.push(
        <line
          key={`h-${i}`}
          x1={0}
          y1={position}
          x2={1}
          y2={position}
          stroke="#B0B0B0"
          strokeWidth={0.002}
        />
      );
    }
  }
  return gridElements;
};

function renderTraces(traces: Trace[], showDirections: boolean) {
  const circleRadius = 0.025;
  const labelFontSize = 0.02;
  return traces.map((trace) => (
    <g>
      {showDirections &&
        // render arrows for all points in path
        trace.path.slice(1).map((point, i) => {
          const prevPoint = trace.path[i];
          return renderArrow(
            prevPoint.x,
            prevPoint.y,
            point.x,
            point.y,
            i === trace.path.length - 2 ? 0.025 : 0, // arrow head only for last point
            {
              stroke: '#38414F',
              strokeWidth: 0.0038,
              opacity: 1,
            }
          );
        })}
      {/*render circle for first point in path*/}
      <circle
        cx={trace.path[0].x}
        cy={trace.path[0].y}
        r={circleRadius}
        fill={trace.color}
      />
      {/*render trace.label inside circle*/}
      <text
        x={trace.path[0].x}
        y={trace.path[0].y}
        fontSize={labelFontSize}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {trace.label ? trace.label.replace(/-/g, '') : ''}
      </text>
    </g>
  ));
}

export default function DmaMatrixPlot(props: Props) {
  const { t } = useTranslation();

  const arrowHeadSize = 0.025;
  const arrowStrokeWidth = 0.0038;
  const labelFontSize = 0.03;

  const highPriorityColor = 'hsl(43, 79%, 75%)';
  const mediumPriorityColor = 'hsl(43, 79%, 85%)';
  const lowPriorityColor = 'hsl(43, 79%, 95%)';

  // we need to flip the y-axis because the svg coordinate system has the y-axis pointing downwards
  const flippedTraces = props.traces.map((trace) => ({
    ...trace,
    path: trace.path.map((point) => ({
      x: point.x / SCALING_FACTOR,
      y: 1 - point.y / SCALING_FACTOR,
    })),
  }));

  return (
    <svg viewBox="-0.1 -0.1 2 1.2" xmlns="http://www.w3.org/2000/svg">
      <g>
        {/*priority areas*/}
        <clipPath id="clip">
          <rect x={0} y={0} width={1} height={1} />
        </clipPath>
        <g clipPath="url(#clip)">
          {/* High Priority - Top Right Quadrant */}
          <rect
            x={0.5}
            y={0}
            width={0.5}
            height={0.5}
            fill={highPriorityColor}
          />
          {/* Medium Priority - Top Left and Bottom Right Quadrants */}
          <rect
            x={0}
            y={0}
            width={0.5}
            height={0.5}
            fill={mediumPriorityColor}
          />
          <rect
            x={0.5}
            y={0.5}
            width={0.5}
            height={0.5}
            fill={mediumPriorityColor}
          />
          {/* Low Priority - Bottom Left Quadrant */}
          <rect
            x={0}
            y={0.5}
            width={0.5}
            height={0.5}
            fill={lowPriorityColor}
          />
        </g>

        {/* legend */}
        <rect
          x={1.05}
          y={0.0}
          width={0.1}
          height={0.05}
          fill={highPriorityColor}
          stroke="#38414F"
          strokeWidth={0.001}
        />
        <text
          x={1.2}
          y={0.025}
          fontSize={labelFontSize}
          textAnchor="start"
          dominantBaseline="middle"
        >
          {t('esg.dmaMatrix.highPriority')}
        </text>

        <rect
          x={1.05}
          y={0.075}
          width={0.1}
          height={0.05}
          fill={mediumPriorityColor}
          stroke="#38414F"
          strokeWidth={0.001}
        />
        <text
          x={1.2}
          y={0.1}
          fontSize={labelFontSize}
          textAnchor="start"
          dominantBaseline="middle"
        >
          {t('esg.dmaMatrix.mediumPriority')}
        </text>

        <rect
          x={1.05}
          y={0.15}
          width={0.1}
          height={0.05}
          fill={lowPriorityColor}
          stroke="#38414F"
          strokeWidth={0.001}
        />
        <text
          x={1.2}
          y={0.175}
          fontSize={labelFontSize}
          textAnchor="start"
          dominantBaseline="middle"
        >
          {t('esg.dmaMatrix.lowPriority')}
        </text>
        {renderGrid()}

        {renderArrow(1.05, 0.25, 1.15, 0.25, 0.03, {
          stroke: '#38414F',
          strokeWidth: arrowStrokeWidth,
          opacity: 0.3,
        })}
        <text
          x={1.2}
          y={0.25}
          fontSize={labelFontSize}
          textAnchor="start"
          dominantBaseline="middle"
        >
          {t('esg.dmaMatrix.changeDirection')}
        </text>

        {/*axes*/}

        {/*axes arrows*/}
        {renderArrow(0, 1, 0, -0.05, arrowHeadSize, {
          stroke: '#6E6E6E',
          strokeWidth: arrowStrokeWidth,
        })}
        {renderArrow(0, 1, 1.06, 1, arrowHeadSize, {
          stroke: '#6E6E6E',
          strokeWidth: arrowStrokeWidth,
        })}
        {/* add labels*/}
        <text
          x={0.5}
          y={1.03}
          fontSize={labelFontSize}
          fontWeight={600}
          textAnchor="middle"
          dominantBaseline="middle"
          transform={`translate(0, ${labelFontSize})`}
          fill="#38414F"
        >
          {t('esg.dmaMatrix.financialScore')}
        </text>
        <text
          x={0.5}
          y={1.062}
          fontSize="0.022"
          textAnchor="middle"
          dominantBaseline="middle"
          transform={`translate(0, ${labelFontSize})`}
          fill="#38414F"
        >
          {t('esg.dmaMatrix.outSideIn')}
        </text>
        <text
          x={0.5}
          y={0.95}
          fontSize={labelFontSize}
          fontWeight={600}
          textAnchor="middle"
          dominantBaseline="middle"
          transform={`translate(${-labelFontSize}, 0) rotate(-90, 0, 1)`}
          fill="#38414F"
        >
          {t('esg.dmaMatrix.nonfinancialScore')}
        </text>
        <text
          x={0.5}
          y={0.98}
          fontSize="0.022"
          textAnchor="middle"
          dominantBaseline="middle"
          transform={`translate(${-labelFontSize}, 0) rotate(-90, 0, 1)`}
          fill="#38414F"
        >
          {t('esg.dmaMatrix.inSideOut')}
        </text>

        {renderTraces(flippedTraces, props.showDirections)}
      </g>
    </svg>
  );
}
