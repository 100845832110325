import { useQuery } from 'react-query';
import { Qv3EnumSchema } from './types';
import { client } from 'src/utils/api-client';

// eslint-disable-next-line import/prefer-default-export
export const useQv3Enums = (id?: number) => {
  const queryKey = ['qv3Enums', { id }];
  const url = `/api-questionnaires/enums/${id}`;
  const query = useQuery<Qv3EnumSchema>(
    queryKey,
    () => client.get(url).then((res) => res.data),
    { enabled: !!id }
  );
  const qv3Enum = query.data || { name: null, default: null, options: [] };
  return { qv3Enum, ...query };
};
