import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import DmaMatrixPlot from './Plot';
import { StyledPanel, StyledTitle } from 'src/Esg/styles';

import DmaMatrixTable from './Table';
import { useDmaMatrix } from '../hooks';
import { useEsgReports } from 'src/Esg/hooks';

export interface Score {
  financial?: number | unknown;
  nonfinancial?: number | unknown;
}

export interface TimeHorizonScores {
  short: Score;
  medium: Score;
  long: Score;
}

export enum ImpactLevel {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export default function DmaMatrix() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _data, isSuccess, isError } = useDmaMatrix(report?.esg_dma_id, {
    enabeld: !!report,
  });

  const [showDirections, setShowDirections] = useState<boolean>(true);
  const [hiddenDataPoints, setHiddenDataPoints] = useState<number[]>([]);

  const toggleDataPointVisibility = (id: number) =>
    setHiddenDataPoints((prevState) =>
      hiddenDataPoints.includes(id)
        ? prevState.filter((_id) => _id !== id)
        : [...prevState, id]
    );

  if (isError) {
    return <SomethingWentWrong />;
  }
  if (!isSuccess) {
    return <PageLoading />;
  }

  if (_data.length === 0)
    return <Typography variant="h5">{t('esg.dmaMatrix.noData')}</Typography>;

  const traces = _data
    .filter(({ id }) => !hiddenDataPoints.includes(id))
    .map((datapoint) => ({
      color: datapoint.esg_topic_color || 'yellow',
      label: String(datapoint.id),
      path: [
        {
          x: Number(datapoint.scores.short.financial),
          y: Number(datapoint.scores.short.nonfinancial),
        },
        {
          x: Number(datapoint.scores.medium.financial),
          y: Number(datapoint.scores.medium.nonfinancial),
        },
        {
          x: Number(datapoint.scores.long.financial),
          y: Number(datapoint.scores.long.nonfinancial),
        },
      ].filter((point) => Number.isFinite(point.x) && Number.isFinite(point.y)),
    }));

  return (
    <>
      <StyledTitle>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#38414f' }} />
        </IconButton>
        {t('esg.dmaMatrix.title')}
      </StyledTitle>
      <Box
        style={{
          padding: '36px 32px',
          borderRadius: '16px',
          border: '1px solid #ccc',
          background: '#fff',
          color: '#38414F',
        }}
      >
        <Box width="800px">
          <DmaMatrixPlot showDirections={showDirections} traces={traces} />
        </Box>
        <Box display="flex" mb="20px">
          <IconButton
            sx={{ color: '#38414F' }}
            onClick={() => setShowDirections(!showDirections)}
          >
            {showDirections ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </IconButton>
          <Typography mt="10px" variant="inherit" sx={{ color: '#38414F' }}>
            {t('esg.dmaMatrix.showLongTermDirection')}
          </Typography>
        </Box>
        {t('esg.dmaMatrix.topicListTitle')}
        <ul>
          {_data.map((datapoint) => (
            <li key={datapoint.id}>
              {datapoint.id}: {datapoint.esg_topic_title} (
              {datapoint.esg_topic_key})
            </li>
          ))}
        </ul>
      </Box>

      <br />
      <StyledPanel>
        <DmaMatrixTable
          data={_data}
          onToggleDataPointVisibility={toggleDataPointVisibility}
          hiddenDataPoints={hiddenDataPoints}
        />
      </StyledPanel>
    </>
  );
}
