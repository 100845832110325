import React, { useEffect } from 'react';

import QuestionnaireAnswer from '../QuestionnaireAnswer';
import { QuestionnaireAnswer as QuestionnaireAnswerType } from '../QuestionnaireAnswer/index.types';

import { ModifiedQuestionAnswers, QuestionnaireV3 } from '../types';
import { injectNewAnswers } from '../helpers';
import { useLanguage } from 'src/common/hooks';
import { Errors } from '../Field/Fields/types';
import { parseResponseMessage } from './utils';
import { toast } from 'react-toastify';
import QuestionnaireContext from './QuestionnaireContext';

interface Props extends QuestionnaireV3 {
  data?: QuestionnaireAnswerType;
  refetch: () => Promise<any>;
}

export default function QuestionnaireContextWrapped(props: Props) {
  const {
    tokenAuth,
    refetch,
    data,
    questionnaireAnswerId,
    withBulkSaveButtons,
  } = props;

  const [errors, setErrors] = React.useState<Errors>({});

  const [questionnaireAnswerState, setQuestionnaireAnswerState] =
    React.useState<QuestionnaireAnswerType | undefined>(data);

  const [modifiedQuestionAnswers, setModifiedQuestionAnswers] =
    React.useState<ModifiedQuestionAnswers>({});

  const { language: activeLanguage } = useLanguage();

  const invalidateQuestionnaireQuery = React.useCallback(
    (_modifiedQuestionAnswers?: ModifiedQuestionAnswers) => {
      refetch().then((data) =>
        setQuestionnaireAnswerState(
          injectNewAnswers(data, _modifiedQuestionAnswers)
        )
      );
    },
    [refetch]
  );

  useEffect(() => {
    setQuestionnaireAnswerState(data);
  }, [data]);

  useEffect(() => {
    // handle changing app language
    // TODO: prompt the user before reloading the questionnaire
    invalidateQuestionnaireQuery();
  }, [invalidateQuestionnaireQuery, activeLanguage]);

  const memoizedValue = React.useMemo(
    () => ({
      questionnaireAnswerId,
      invalidateQuestionnaireQuery,
      questionnaireAnswerState,
      setQuestionnaireAnswerState,
      tokenAuth,
      modifiedQuestionAnswers,
      setModifiedQuestionAnswers,
      errors,
      setErrors: (errors: Errors) => {
        const _errors = parseResponseMessage(errors);
        setErrors(_errors);
        for (const value of Object.values(_errors)) {
          toast.error(value);
        }
      },
    }),
    [
      questionnaireAnswerId,
      invalidateQuestionnaireQuery,
      questionnaireAnswerState,
      setQuestionnaireAnswerState,
      tokenAuth,
      modifiedQuestionAnswers,
      setModifiedQuestionAnswers,
      errors,
      setErrors,
    ]
  );

  if (!questionnaireAnswerState) return null;

  return (
    <QuestionnaireContext.Provider value={memoizedValue}>
      <QuestionnaireAnswer
        payload={questionnaireAnswerState}
        withBulkSaveButtons={withBulkSaveButtons}
      />
    </QuestionnaireContext.Provider>
  );
}
