import React from 'react';
import { Box, Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { EsgDmaImpactCategory, EsgDmaImpactOutSchema } from '../../../types';
import CustomSelect from 'src/components/CustomSelect';
import { useDmaImpacts } from '../../../hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from 'src/components/ConfirmationModal';
import EditModal from 'src/Esg/Dma/Stakeholders/SelectingStakeholders/SelectingStakeholdersTable/EditModal';
import { useLanguage } from 'src/common/hooks';
import colors from 'src/theme/colors';
import AcceptableTextField from 'src/components/AcceptableTextField';
import DescritionMark from 'src/Esg/Dmav2/components/DescriptionMark';

const selectOptionsYesNoColors: {
  [key: string]: { main: string; contrastText: string };
} = {
  true: {
    main: colors.esgMainDark,
    contrastText: colors.pureWhite,
  },
  false: {
    main: colors.disabledGraphics,
    contrastText: colors.esgMain,
  },
};

interface Props {
  impact: EsgDmaImpactOutSchema;
}

export default function ImpactRow(props: Props) {
  const { impact } = props;
  const { update, _delete } = useDmaImpacts({}, { enabled: false });
  const { t } = useLanguage();

  const yesNoOptions = [
    { value: true, label: t('common.yes') },
    { value: false, label: t('common.no') },
  ];

  const categoryOptions = Object.entries(EsgDmaImpactCategory).map(
    ([key, value]) => ({
      value,
      label: t(`esg.dma.tableV2.category.${key}`),
    })
  );

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);

  return (
    <TableRow>
      <TableCell
        sx={{
          pl: '64px',
          backgroundColor: colors.backgroundInput,
          minWidth: '300px',
        }}
      >
        <AcceptableTextField
          size="small"
          value={impact.name}
          onAccept={(value) =>
            update.mutate({ ...impact, name: String(value) })
          }
        />
      </TableCell>
      <TableCell align="center">
        <Box position="relative" display="inline-flex" alignItems="center">
          <EditModal
            title={t('esg.dma.tableV2.columns.description')}
            value={impact.description}
            defaultValue=""
            onChange={(value) =>
              update.mutate({ ...impact, description: value })
            }
          />
          <DescritionMark hasDescription={!!impact.description} />
        </Box>
      </TableCell>
      <TableCell>
        <CustomSelect
          options={categoryOptions}
          value={impact.category}
          onChange={(value) => update.mutate({ ...impact, category: value })}
        />
      </TableCell>
      <TableCell>
        <CustomSelect
          options={yesNoOptions}
          colors={selectOptionsYesNoColors}
          value={impact.human_rights_related}
          onChange={(value) =>
            update.mutate({ ...impact, human_rights_related: value })
          }
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{ backgroundColor: colors.backgroundInput }}
      >
        <Checkbox
          checked={impact.upstream_related}
          onChange={(e) =>
            update.mutate({ ...impact, upstream_related: e.target.checked })
          }
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{ backgroundColor: colors.backgroundGreyDark }}
      >
        <Checkbox
          checked={impact.own_operations_related}
          onChange={(e) =>
            update.mutate({
              ...impact,
              own_operations_related: e.target.checked,
            })
          }
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{ backgroundColor: colors.backgroundInput }}
      >
        <Checkbox
          checked={impact.downstream_related}
          onChange={(e) =>
            update.mutate({
              ...impact,
              downstream_related: e.target.checked,
            })
          }
        />
      </TableCell>

      <TableCell>
        <IconButton onClick={() => setDeleteConfirmationOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={() => _delete.mutate(impact.id)}
        titleKey={t('esg.dma.tableV2.removeImpact')}
      />
    </TableRow>
  );
}
