import React from 'react';
import {
  Route,
  Routes,
  Navigate,
  Outlet,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import esgTheme from './theme';
import ESGForm from 'src/views/Pages/ESG/forms';
import CreateESGReport from 'src/views/Pages/ESG/create';

import ReportsIndex from 'src/views/Pages/ESG/ReportsIndex';
import EsgReportRoute from './routes';
import ReportPage from 'src/views/Pages/ESG/Report';
import Indicators from 'src/views/Pages/ESG/Report/indicators';
import SummaryPage from 'src/views/Pages/ESG/Report/summary';
import DownloadPage from 'src/views/Pages/ESG/Report/download';

import DmaStakeholders from './Dma/Stakeholders';
import DmaStakeholdersAnalytics from './Dma/Stakeholders/StakeholdersAnalytics';
import DmaSelectingStakeholders from './Dma/Stakeholders/SelectingStakeholders';
import Step2TopicEvaluation from './Dma/Step2TopicEvaluation';
import Step3TopicEvaluation from './Dma/Step3TopicEvaluation';
import Step5TopicEvaluation from './Dma/Step5TopicEvaluation';
import Step6TopicEvaluation from './Dma/Step6TopicEvaluation';
import DmaMatrixView from './Dma/components/DmaMatrix';
import DmaStakeholdersAnalyticsDashboard from './Dma/Stakeholders/StakeholdersAnalytics/Dashboard';
import DmaStakeholdersAnalyticsTable from './Dma/Stakeholders/StakeholdersAnalytics/Table';
import DmaStakeholdersAnalyticsSubtopic from './Dma/Stakeholders/StakeholdersAnalytics/Subtopic';
import DmaStakeholdersAnalyticsIndividual from './Dma/Stakeholders/StakeholdersAnalytics/Individual';
import { RouteLayout } from 'src/views/components/Layout';
import EsgOrganizationSidebar from './components/OrganizationSidebar';
import TopBar from '../components/TopBar';
import {
  PermissionRoute,
  ProductHomeRoute,
  ProductOrganizationRoute,
} from '../common/routes';
import { ProductPlatform } from '../utils/api.interfaces';
import EsgHome from './Home';
import EsgReportHome from './ReportHome';
import EsgAdministratonPanel from './AdministrationPanel';
import Dmav2 from './Dmav2';
import Step1 from './Dmav2/Step1';
import Step2 from './Dmav2/Step2';
import QualityAnswers from './Dmav2/Stakeholders/QualityAnswers';
import Step5 from './Dmav2/Step5';

import DmaStakeholdersV2 from './Dmav2/Stakeholders';
import DmaStakeholdersAnalyticsV2 from './Dmav2/Stakeholders/StakeholdersAnalytics';
import DmaSelectingStakeholdersV2 from './Dmav2/Stakeholders/SelectingStakeholders';
import DmaStakeholdersAnalyticsDashboardV2 from './Dmav2/Stakeholders/StakeholdersAnalytics/Dashboard';
import Step3 from './Dmav2/Step3';
import DmaMatrix from './Dmav2/DmaMatrix';
import Dma from './Dma';
import { useEsgReports } from './hooks';
import { useEsgDmas } from './Dmav2/hooks';
import { DmaVersion } from './Dmav2/types';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { useBasicSurveyMeta } from '../views/Pages/ESG/StakeholderSurvey/hooks';
import StakeholderSurveyIntro from '../views/Pages/ESG/StakeholderSurvey/Intro';
import StakeholderSurveyQuestionnaire from '../views/Pages/ESG/StakeholderSurvey/Questionnaire';
import StakeholderSurveySummary from '../views/Pages/ESG/StakeholderSurvey/Summary';
import StakeholderSurveyEnd from '../views/Pages/ESG/StakeholderSurvey/End';
import jwtDecode from 'jwt-decode';

const EsgHomeRoute = () => (
  <ProductHomeRoute>
    <EsgHome />
  </ProductHomeRoute>
);

const EsgOrganizationRoute = () => (
  <ProductOrganizationRoute product={ProductPlatform.Esg} />
);

const EsgLayoutRoute = () => (
  <RouteLayout sidebar={<EsgOrganizationSidebar />} topbar={<TopBar />} />
);

// guards the correct version of the DMA
const DmaVersionGuard = () => {
  const location = useLocation();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma } = useEsgDmas(
    { id: report?.esg_dma_id },
    { enabled: !!report }
  );

  if (!report || !dma) return <PageLoading />;

  const { pathname } = location;
  const correctDmaPath = `/esg/reports/${reportId}/dma/v${dma.version}`;
  const isPathVersionCorrect = pathname.includes(correctDmaPath);
  if (!isPathVersionCorrect) return <Navigate to={correctDmaPath} />;

  return <Outlet />;
};

export default function Esg() {
  return (
    <ThemeProvider theme={esgTheme}>
      <Routes>
        <Route index element={<EsgHomeRoute />} />
        <Route element={<EsgOrganizationRoute />}>
          <Route element={<EsgLayoutRoute />}>
            <Route
              path="administration-panel"
              element={<EsgAdministratonPanel />}
            />
            <Route
              path="dashboard"
              element={<Navigate to="/esg/reports" replace />}
            />
            <Route path="reports" element={<ReportsIndex />} />
            <Route element={<PermissionRoute writeData />}>
              <Route path="reports/new" element={<CreateESGReport />} />
            </Route>
            <Route element={<EsgReportRoute />}>
              <Route path="reports/:reportId" element={<EsgReportRoute />}>
                <Route index element={<EsgReportHome />} />
                <Route element={<ReportPage />}>
                  <Route path="indicators" element={<Indicators />} />
                  <Route path="summary" element={<SummaryPage />} />
                  <Route path="download" element={<DownloadPage />} />
                </Route>
                <Route element={<PermissionRoute writeData />}>
                  <Route path="questionnaires/:formKey" element={<ESGForm />} />

                  <Route path="dma" element={<DmaVersionGuard />}>
                    {/* DMA version guard */}

                    {/* ================== ESG DMA V1================== */}
                    <Route path="v1">
                      <Route
                        index
                        element={
                          <ReportPage>
                            <Dma />
                          </ReportPage>
                        }
                      />
                      <Route element={<DmaStakeholders />}>
                        <Route element={<DmaStakeholdersAnalytics />}>
                          <Route
                            path="stakeholders/analytics/dashboard"
                            element={<DmaStakeholdersAnalyticsDashboard />}
                          />
                          <Route
                            path="stakeholders/analytics/table"
                            element={<DmaStakeholdersAnalyticsTable />}
                          />
                          <Route
                            path="stakeholders/analytics/subtopic"
                            element={<DmaStakeholdersAnalyticsSubtopic />}
                          />
                          <Route
                            path="stakeholders/analytics/individual"
                            element={<DmaStakeholdersAnalyticsIndividual />}
                          />
                        </Route>
                        <Route
                          path="stakeholders/selecting-stakeholders"
                          element={<DmaSelectingStakeholders />}
                        />
                      </Route>
                      <Route
                        path="step-2/topic-evaluation"
                        element={<Step2TopicEvaluation />}
                      />
                      <Route
                        path="step-3/topic-evaluation"
                        element={<Step3TopicEvaluation />}
                      />
                      <Route
                        path="step-5/topic-evaluation"
                        element={<Step5TopicEvaluation />}
                      />
                      <Route
                        path="step-6/topic-evaluation"
                        element={<Step6TopicEvaluation />}
                      />
                      <Route path="matrix" element={<DmaMatrixView />} />
                    </Route>

                    {/* ================== ESG DMA V2 ================== */}
                    <Route path="v2">
                      <Route
                        index
                        element={
                          <ReportPage>
                            <Dmav2 />
                          </ReportPage>
                        }
                      />
                      <Route path="step-1" element={<Step1 />} />
                      <Route path="step-2" element={<Step2 />} />
                      <Route path="step-3" element={<Step3 />} />
                      <Route path="step-5" element={<Step5 />} />
                      <Route path="matrix" element={<DmaMatrix />} />
                      <Route element={<DmaStakeholdersV2 />}>
                        <Route
                          path="stakeholders/selecting-stakeholders"
                          element={<DmaSelectingStakeholdersV2 />}
                        />
                        <Route element={<DmaStakeholdersAnalyticsV2 />}>
                          <Route
                            path="stakeholders/analytics/dashboard"
                            element={<DmaStakeholdersAnalyticsDashboardV2 />}
                          />
                        </Route>
                        <Route
                          path="stakeholders/quality-answers"
                          element={<QualityAnswers />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export const EsgLegacyStakeholderSurveyRoute = () => {
  const { token } = useParams();
  const { data: meta, isError } = useBasicSurveyMeta(token);

  if (isError) return <SomethingWentWrong />;
  if (!meta) return <PageLoading />;

  return (
    <Routes>
      <Route index element={<StakeholderSurveyIntro />} />
      <Route
        path="questionnaire/:idx?"
        element={<StakeholderSurveyQuestionnaire />}
      />
      <Route path="summary" element={<StakeholderSurveySummary />} />
      <Route path="end" element={<StakeholderSurveyEnd />} />
    </Routes>
  );
};
export const EsgLegacyStakeholderSurveyNavigator = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { token } = useParams();
  const LEGACY_AUD = 'esg_stakeholder_survey_answer';
  if (token && jwtDecode<{ aud: string }>(token).aud === LEGACY_AUD)
    return <Navigate to={`/stakeholders-legacy/${token}`} />;
  return children;
};
