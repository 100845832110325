import React from 'react';

import { Field as FieldType } from './index.types';
import { getFieldComponent } from './Fields';
import { FieldValue, Errors } from './Fields/types';

type Props = {
  field: FieldType;
  errors: Errors;
  value?: FieldValue;
  onForceQuestionAnswerSave: () => void;
  onChange: (field: FieldType, value: FieldValue) => void;
};

export default function Field(props: Props) {
  const { field, errors, onChange, onForceQuestionAnswerSave } = props;

  const FieldComponent = React.useMemo(
    () => getFieldComponent(field.visual_type),
    [field.visual_type]
  );

  const onChangeCallback = React.useCallback(
    (value: FieldValue) => {
      onChange(field, value);
    },
    [field, onChange]
  );

  const value = props.value ?? '';

  return (
    <FieldComponent
      field={field}
      value={value}
      onChange={onChangeCallback}
      errors={errors}
      onForceQuestionAnswerSave={onForceQuestionAnswerSave}
    />
  );
}
