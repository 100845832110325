import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';

import { useDmaHierarchy } from '../../hooks';
import Head from './Head';
import TitleRow from './Rows/TitleRow';
import { StyledPanel } from 'src/Esg/styles';

export default function Step5Table() {
  const { stages23Hierarchy } = useDmaHierarchy();
  return (
    <StyledPanel>
      <TableContainer>
        <Table>
          <Head />
          <TableBody>
            {Object.entries(stages23Hierarchy).map(([title, data]) => (
              <TitleRow key={title} title={title} data={data} />
            ))}
          </TableBody>
          <br />
        </Table>
      </TableContainer>
    </StyledPanel>
  );
}
